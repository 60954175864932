var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-main"},[_c('el-tabs',{on:{"tab-click":_vm.tabChange},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"工艺确认","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"项目排程","name":"2"}})],1),(_vm.activeName == 1)?_c('div',{staticClass:"table-list"},[_c('el-table',{ref:"multipleTable",staticClass:"craft-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark","size":"small","height":"350px","border":""}},[_c('el-table-column',{attrs:{"label":"工艺文件名称","prop":"fileName","width":"200"}}),_c('el-table-column',{attrs:{"label":"文件大小","prop":"fileEntity.fileSizeFormat","align":"center"}}),_c('el-table-column',{attrs:{"label":"上传时间","width":"150","prop":"createDate","align":"center"}}),_c('el-table-column',{attrs:{"label":"下载量","prop":"createByName","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" 已下载 "),_c('span',{staticStyle:{"color":"#232ae6"}},[_vm._v(" "+_vm._s((scope.row.extend && scope.row.extend.extendI4) || 0)+" ")]),_vm._v(" 次 ")])]}}],null,false,4071988983)}),(_vm.hasShop)?_c('el-table-column',{attrs:{"label":"需求方状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.extend && scope.row.extend.extendS2)?_c('span',{class:{
              'affirm-span': true,
              on: scope.row.extend && scope.row.extend.extendS2 == 1,
            }},[_vm._v(_vm._s(scope.row.extend && scope.row.extend.extendS2 == 1 ? "已确认" : "已否决"))]):_c('span',[_vm._v("待确认")])]}}],null,false,4246007806)}):_vm._e(),(!_vm.hasShop)?_c('el-table-column',{attrs:{"label":"供应商状态","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.extend && scope.row.extend.extendS4)?_c('span',{class:{
              'affirm-span': true,
              on: scope.row.extend && scope.row.extend.extendS5 == 1,
            }},[_vm._v(_vm._s(scope.row.extend && scope.row.extend.extendS5 == 1 ? "已确认" : "已否决"))]):_c('span',[_vm._v("待确认")])]}}],null,false,1895771128)}):_vm._e(),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-download"},on:{"click":function($event){return _vm.goDown(scope.row)}}},[_vm._v("下载")]),(
              scope.row.createBy != _vm.userInfo.userCode &&
              ((scope.row.extend && !scope.row.extend.extendS4) ||
                !scope.row.extend) &&
              _vm.hasShop
            )?_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-circle-check","loading":scope.row.yesLoad},on:{"click":function($event){return _vm.goYes(scope.row)}}},[_vm._v("确认")]):_vm._e(),(
              scope.row.createBy != _vm.userInfo.userCode &&
              ((scope.row.extend && !scope.row.extend.extendS4) ||
                !scope.row.extend) &&
              _vm.hasShop
            )?_c('el-button',{attrs:{"type":"danger","size":"mini","icon":"el-icon-circle-close","loading":scope.row.notLoad},on:{"click":function($event){return _vm.goNot(scope.row)}}},[_vm._v("否决")]):_vm._e(),(
              scope.row.createBy != _vm.userInfo.userCode &&
              ((scope.row.extend && !scope.row.extend.extendS3) ||
                !scope.row.extend) &&
              !_vm.hasShop
            )?_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-circle-check","loading":scope.row.yesLoad},on:{"click":function($event){return _vm.goYes(scope.row, true)}}},[_vm._v("确认")]):_vm._e(),(
              scope.row.createBy != _vm.userInfo.userCode &&
              ((scope.row.extend && !scope.row.extend.extendS3) ||
                !scope.row.extend) &&
              !_vm.hasShop
            )?_c('el-button',{attrs:{"type":"danger","size":"mini","icon":"el-icon-circle-close","loading":scope.row.notLoad},on:{"click":function($event){return _vm.goNot(scope.row, true)}}},[_vm._v("否决")]):_vm._e()]}}],null,false,3342665559)})],1),(!_vm.shoperUserSureExecuteStatus && !_vm.taskUserSureExecuteStatus)?_c('div',{staticClass:"f-btn"},[_c('upload',{ref:"upload",attrs:{"mode":"craft","btnText":"上传工艺文件","format":"stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi","uploadType":"all"},on:{"checkSuccess":_vm.checkSuccess,"success":_vm.upSuccess}})],1):_vm._e()],1):_vm._e(),(_vm.activeName == 2)?_c('div',{staticClass:"table-list"},[(_vm.commonStatus.needTime)?_c('el-form',{ref:"projectForm",staticClass:"project-form",attrs:{"model":_vm.projectForm,"size":"small","label-suffix":"："}},[_c('el-form-item',{attrs:{"prop":"duration","label":"工期范围"}},[_c('span',[_vm._v(_vm._s(_vm.commonStatus.beginTime.split(" ")[0])+"至 "+_vm._s(_vm.commonStatus.endTime.split(" ")[0]))]),_c('span',{staticClass:"days"},[_vm._v("工期："+_vm._s(_vm.commonStatus.needTime)+"天")])])],1):_vm._e(),_c('el-form',{ref:"tablelists",class:[
        'tablelists-form',
        _vm.shoperUserSureExecuteStatus == 1 && _vm.taskUserSureExecuteStatus == 1
          ? 'on'
          : '' ],attrs:{"model":_vm.forms,"size":"small"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoad),expression:"tableLoad"}],ref:"multipleTable",class:_vm.hasSort ? 'has-sort' : '',staticStyle:{"width":"100%"},attrs:{"size":"small","data":_vm.forms.yzTaskOrderPlanList,"border":"","empty-text":" ","row-key":"id","fit":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"prop":"sort","align":"center","label":"编号","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请输入排序',
                trigger: 'blur',
              },"prop":("yzTaskOrderPlanList." + (scope.$index) + ".sort")}},[(!scope.row.preview)?_c('el-input',{model:{value:(scope.row.sort),callback:function ($$v) {_vm.$set(scope.row, "sort", $$v)},expression:"scope.row.sort"}}):_vm._e(),(scope.row.preview)?_c('p',[_vm._v(_vm._s(scope.row.sort))]):_vm._e()],1)]}}],null,false,2375898598)}),_c('el-table-column',{attrs:{"prop":"step","align":"center","label":"项目阶段"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskOrderPlanList." + (scope.$index) + ".step"),"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请选择项目阶段',
                trigger: 'change',
              }}},[(!scope.row.preview)?_c('el-select',{attrs:{"placeholder":"请选择","filterable":"","allow-create":"","default-first-option":""},model:{value:(scope.row.step),callback:function ($$v) {_vm.$set(scope.row, "step", $$v)},expression:"scope.row.step"}},_vm._l((_vm.stepList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1):_vm._e(),(scope.row.preview)?_c('p',[_vm._v(" "+_vm._s(_vm.stepJson[scope.row.step] || scope.row.step)+" ")]):_vm._e()],1)]}}],null,false,18480945)}),_c('el-table-column',{attrs:{"prop":"workContent","align":"center","label":"项目内容"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskOrderPlanList." + (scope.$index) + ".workContent"),"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请输入项目内容',
                trigger: 'blur',
              }}},[(!scope.row.preview)?_c('el-input',{attrs:{"placeholder":"请输入项目内容"},model:{value:(scope.row.workContent),callback:function ($$v) {_vm.$set(scope.row, "workContent", $$v)},expression:"scope.row.workContent"}}):_vm._e(),(scope.row.preview)?_c('p',[_vm._v(_vm._s(scope.row.workContent))]):_vm._e()],1)]}}],null,false,2785146849)}),_c('el-table-column',{attrs:{"prop":"commitContent","align":"center","label":"交付成果"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskOrderPlanList." + (scope.$index) + ".commitContent"),"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请选择交付成果',
                trigger: 'blur',
              }}},[(!scope.row.preview)?_c('el-select',{attrs:{"placeholder":"请选择","filterable":"","allow-create":"","default-first-option":""},model:{value:(scope.row.commitContent),callback:function ($$v) {_vm.$set(scope.row, "commitContent", $$v)},expression:"scope.row.commitContent"}},_vm._l((_vm.statusList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1):_vm._e(),(scope.row.preview)?_c('p',[_vm._v(" "+_vm._s(_vm.statusJson[scope.row.commitContent] || scope.row.commitContent)+" ")]):_vm._e()],1)]}}],null,false,966022458)}),_c('el-table-column',{attrs:{"align":"center","label":"阶段状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('p',[(
                    scope.row.taskUserSureExecuteStatus !== undefined &&
                    scope.row.shoperUserSureExecuteStatus !== undefined
                  )?_c('span',{class:[
                    'affirm-span',
                    _vm.hasShop
                      ? scope.row.taskUserSureExecuteStatus == 1
                        ? 'on'
                        : ''
                      : scope.row.shoperUserSureExecuteStatus == 1
                      ? 'on'
                      : '' ]},[_vm._v(_vm._s(_vm.operateStatusJson[ _vm.hasShop ? scope.row.taskUserSureExecuteStatus : scope.row.shoperUserSureExecuteStatus ]))]):_c('span',[_vm._v(_vm._s(_vm.hasShop ? "需求方待确认" : "服务商待确认"))])])])]}}],null,false,309679598)}),_c('el-table-column',{attrs:{"prop":"commitMoney","align":"center","label":"付款金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskOrderPlanList." + (scope.$index) + ".commitMoney"),"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请输入付款金额',
                trigger: 'blur',
              }}},[(!scope.row.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入付款金额"},model:{value:(scope.row.commitMoney),callback:function ($$v) {_vm.$set(scope.row, "commitMoney", $$v)},expression:"scope.row.commitMoney"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("元")])]):_vm._e(),(scope.row.preview)?_c('p',[_vm._v(_vm._s(scope.row.commitMoney)+"元")]):_vm._e()],1)]}}],null,false,283181867)}),_c('el-table-column',{attrs:{"prop":"needArea","align":"center","width":"210","label":"工期范围"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskOrderPlanList." + (scope.$index) + ".needArea"),"rules":{
                required: !scope.row.preview && _vm.theId == scope.row.id,
                message: '请选择工期范围',
                trigger: 'blur',
              }}},[_c('div',{staticClass:"picker"},[(!scope.row.preview)?_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"-","start-placeholder":"开始","end-placeholder":"结束","format":"MM-dd"},on:{"change":_vm.needTimeChange},model:{value:(scope.row.needArea),callback:function ($$v) {_vm.$set(scope.row, "needArea", $$v)},expression:"scope.row.needArea"}}):_vm._e()],1),(scope.row.preview && scope.row.beginTime)?_c('p',[_vm._v(" "+_vm._s(scope.row.beginTime.split(" ")[0])+"至"+_vm._s(scope.row.endTime.split(" ")[0])+" ")]):_vm._e()])]}}],null,false,3095491058)}),(_vm.hasShop && !_vm.taskUserSureExecuteStatus)?_c('el-table-column',{attrs:{"prop":"sort","align":"center","label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[(
                  !scope.row.isNewRecord &&
                  scope.row.preview &&
                  scope.row.createBy == _vm.userInfo.userCode
                )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(scope.row.isNewRecord || !scope.row.preview)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.save(scope.row)}}},[_vm._v(" 保存 ")]):_vm._e(),(
                  scope.row.createBy == _vm.userInfo.userCode ||
                  scope.row.isNewRecord
                )?_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(scope.row)}}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}],null,false,81366927)}):_vm._e()],1)],1),(!_vm.hasShop && !_vm.shoperUserSureExecuteStatus)?_c('div',{staticClass:"desc"},[_c('el-input',{attrs:{"type":"textarea","rows":"3","placeholder":"请添加备注"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1):_vm._e(),_c('div',{staticClass:"f-btn"},[(!_vm.hasShop && !_vm.shoperUserSureExecuteStatus)?_c('el-button',{attrs:{"size":"small","type":"success","icon":"el-icon-circle-check","loading":_vm.yesLoad},on:{"click":function($event){return _vm.goYes2()}}},[_vm._v(" 同意 ")]):_vm._e(),(!_vm.hasShop && !_vm.shoperUserSureExecuteStatus)?_c('el-button',{attrs:{"loading":_vm.notLoad,"size":"small","type":"danger","icon":"el-icon-circle-close"},on:{"click":function($event){return _vm.goNot2()}}},[_vm._v(" 否决 ")]):_vm._e(),(_vm.hasShop && !_vm.taskUserSureExecuteStatus)?_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.addSchedule}},[_vm._v("添加项目排程")]):_vm._e(),(_vm.hasShop && !_vm.taskUserSureExecuteStatus)?_c('el-button',{attrs:{"type":"success","size":"small","icon":"el-icon-message"},on:{"click":_vm.goSend}},[_vm._v("提醒客户确认项目规划")]):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }