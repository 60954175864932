var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"invoiceForm",staticClass:"pay-form",attrs:{"model":_vm.invoiceForm,"size":"small"}},[_c('el-form-item',{attrs:{"prop":"description","label":"申请开票","rules":[
      {
        required: true,
        message: '请输入公司税务开票信息',
        trigger: 'blur',
      } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":"5","placeholder":"请输入公司税务开票信息"},model:{value:(_vm.invoiceForm.description),callback:function ($$v) {_vm.$set(_vm.invoiceForm, "description", $$v)},expression:"invoiceForm.description"}})],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"prop":"money","label":"开票金额（元）","rules":[
          {
            required: true,
            message: '请输入开票金额',
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入开票金额"},model:{value:(_vm.invoiceForm.money),callback:function ($$v) {_vm.$set(_vm.invoiceForm, "money", $$v)},expression:"invoiceForm.money"}})],1)],1),_c('el-col',{attrs:{"span":11,"offset":1}},[_c('el-form-item',{attrs:{"prop":"yzInvoiceType","label":"开票类型","rules":[
          {
            required: true,
            message: '请选择开票类型',
            trigger: 'blur',
          } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.invoiceForm.yzInvoiceType),callback:function ($$v) {_vm.$set(_vm.invoiceForm, "yzInvoiceType", $$v)},expression:"invoiceForm.yzInvoiceType"}},_vm._l((_vm.yzInvoiceTypeList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"prop":"filesName","label":"附件上传","rules":[
      {
        required: true,
        message: '请上传附件',
        trigger: 'change',
      } ]}},[_c('upload',{ref:"invoiceUpLoad",attrs:{"fileName":_vm.invoiceForm.filesName},on:{"update:fileName":function($event){return _vm.$set(_vm.invoiceForm, "filesName", $event)},"update:file-name":function($event){return _vm.$set(_vm.invoiceForm, "filesName", $event)},"success":_vm.invoiceSuccess}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.invoiceLoad},on:{"click":_vm.goInvoice}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }