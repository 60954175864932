<template>
  <div class="detail-info">
    <div class="content flex_b">
      <div class="info-l">
        <div class="t-flow">
          <div
            class="item"
            v-for="(item, index) in yzTaskStausList"
            :key="item.dictValue"
          >
            <i class="iconfont icon-you2 s" v-if="index > 0"></i>
            {{ item.dictLabel }}
            <i
              class="iconfont icon-you2 e"
              v-if="index < yzTaskStausList.length - 1"
            ></i>
          </div>
        </div>
        <div class="b">
          <div class="b-item">
            <p v-if="detail.orderSn">
              <span>订单号：{{ detail.orderSn }}</span>
            </p>
            <p class="tip">
              用户{{ detail.nickname }}于<span>{{ detail.createDate }}</span
              >发布该需求
            </p>
            <div class="time" v-if="!isOver">
              投标剩余时间：<span>{{ overDate.d }}</span
              >天<span>{{ overDate.h }}</span
              >时<span>{{ overDate.m }}</span
              >分<span>{{ overDate.s }}</span
              >秒
            </div>
            <div class="time" v-if="isOver">已过期</div>
            <div class="label" v-if="detail.yzCompanyAuthItems">
              雇主对服务商的要求：<span
                v-for="item in detail.yzCompanyAuthItems"
                :key="item"
                >{{ yzCompanyAuthItemsJson[item] }}</span
              >
            </div>
          </div>
          <div class="b-item">
            <p class="line">
              需求名称：<span>{{ detail.title }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              需求类型：<span v-if="detail.yzTaskCategory">{{
                detail.yzTaskCategory.name
              }}</span>
            </p>
          </div>
          <div class="b-item box">
            <p class="line">
              需求数量：<span>{{ detail.taskNum }}</span>
            </p>
            <p class="line">
              时间工期：<span>{{ detail.days }}天</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              预算金额：<span>￥{{ detail.budgetPrice }}</span>
            </p>
          </div>
          <div class="b-item" v-if="detail.yzTaskData.taskUserAddress">
            <p class="line">
              需求方收货地址：<span>{{
                detail.yzTaskData.taskUserAddress
              }}</span>
            </p>
          </div>
          <div class="b-item" v-if="detail.yzTaskData.shoperUserAddress">
            <p class="line">
              供应商收货地址：<span>{{
                detail.yzTaskData.shoperUserAddress
              }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              需求描述：
              <span>{{ detail.description }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              制造文档：<span>{{ detail.taskFileTypes }}</span>
            </p>
          </div>
          <div class="btns">
            <p
              class="common-btn r"
              v-if="
                !detail.currentShoperYzTaskEvaluation &&
                !isUser
              "
              @click="goJoin"
            >
              参与需求
            </p>
            <p class="common-btn" @click="upVisible = true">发布需求</p>
            <a
              v-if="detail.designMainFile"
              :href="detail.designMainFile"
              target="_blank"
              class="common-btn"
              >下载图纸</a
            >
            <p class="common-btn" @click="goChat">在线沟通</p>
          </div>
        </div>
      </div>
      <div class="info-r">
        <make3d ref="make3ds" :boxRight="false" />
      </div>
    </div>
    <up-dialog :visible.sync="upVisible" />
  </div>
</template>
<script>
import upDialog from "@/components/servers/up-dialog";
import make3d from "@/components/make3d/index.vue";
export default {
  components: { make3d, upDialog },
  props: {
    detail: {
      type: Object,
      default: {},
    },
    overDate: {
      type: Object,
      default: { d: 0, h: 0, m: 0, s: 0 },
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      upVisible: false,
      yzTaskStausList: [
        { dictValue: 1, dictLabel: "招标" },
        { dictValue: 2, dictLabel: "工厂报价" },
        { dictValue: 3, dictLabel: "开始生产" },
      ],
      yzCompanyAuthItemsJson: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    isUser() {
      let userInfo = this.$store.state.userInfo;
      return userInfo.userCode == this.detail.taskUser.userCode;
    },
  },
  mounted() {
    this.$sapi
      .get("/api/v1/getDictDataList-yz_company_auth_items.json")
      .then((res) => {
        res.forEach((item) => {
          this.yzCompanyAuthItemsJson[item.dictValue] = item.dictLabel;
        });
      });
  },
  methods: {
    goResize() {
      this.$refs.make3ds.goResize();
      $('.make3d').height($('.make3d').height());
    },
    goJoin() {
      let params = {
        yzTaskMainId: this.detail.id,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/partakeTaskMain", this.$common.getFormData(params))
        .then((res) => {
          if (res.code == "1") {
            this.$api
              .post(
                "/api/v1/updateAssigmentCountCountAddOne",
                this.$common.getFormData({ id: this.detail.id })
              )
              .then((res) => console.log(res.message));
            this.$emit("inits");
            this.$message({
              message: res.message,
              type: "success",
              duration: 1500,
              onClose: () => {
                $("body,html").animate({
                  scrollTop: $(".offer").offset().top - 180,
                });
              },
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
    },
    goChat() {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.yizao2025.com/f/contactKefu?company_id=" + this.detail.id;
    },
  },
};
</script>