<template>
  <div class="detail-tabs">
    <div class="content">
      <div class="common-title">
        <h3>需求详情 <span>DEMAND</span></h3>
      </div>
      <div class="detail-tabs-t">
        <template v-for="item in detailTabs">
          <div
            :class="{ item: true, on: item.id == detailTabsActive }"
            :key="item.id"
            v-if="
              (detail.yzViewShopInfo &&
                item.id == 2 &&
                (userInfo.userCode ==
                  detail.selectShoperYzTaskEvaluation.evaluationUserId ||
                  userInfo.userCode ==
                    detail.selectShoperYzTaskEvaluation.employerUid)) ||
              item.id != 2
            "
            @click="tabsChange(item)"
          >
            {{ item.title }}
          </div>
        </template>
      </div>
      <div class="detail-tabs-item" v-if="detailTabsActive == 1">
        <div class="item">
          <h3>浏览用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.viewCount || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>参与用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.assigmentCount || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>淘汰用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.taobai || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>报价用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.baojiaCount || 0 }}</font
            >人</span
          >
        </div>
      </div>
      <div class="terrace" v-if="detailTabsActive == 2">
        <div class="order-top">
          <div class="order-info">
            <div class="l">
              <p>跟踪订单：{{ detail.orderSn }}</p>
              <span :class="statusClass[detail.yzTaskStep]"
                >【{{ yzTaskStepsJson[detail.yzTaskStep] }}】</span
              >
            </div>
            <div class="r">预计完成日期：2021年5月17日</div>
          </div>
          <div class="order-step">
            <div
              :class="[
                'item',
                orderStep >= 1 ? 'on' : '',
                orderStep == 1 ? 'on active' : '',
              ]"
              @click="orderStepChange(1)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step1.png" alt="" />
              </div>
              <p>订单开始</p>
              <span>---------------->></span>
            </div>
            <div
              :class="[
                'item',
                orderStep >= 2 ? 'on' : '',
                orderStep == 2 ? 'active' : '',
              ]"
              @click="orderStepChange(2)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step2.png" alt="" />
              </div>
              <p>加工进程</p>
              <span>---------------->></span>
            </div>
            <div
              :class="[
                'item',
                orderStep >= 3 ? 'on' : '',
                orderStep == 3 ? 'active' : '',
              ]"
              @click="orderStepChange(3)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step3.png" alt="" />
              </div>
              <p>质量检验</p>
              <span>---------------->></span>
            </div>
            <div
              :class="['item', orderStep == 4 ? 'on active' : '']"
              @click="orderStepChange(4)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step4.png" alt="" />
              </div>
              <p>产品派送</p>
            </div>
          </div>
        </div>
        <div class="order-tabs">
          <div class="l">
            <template v-for="item in orderTabsList">
              <div
                :class="['item', orderTabsActive == item.id ? 'on' : '']"
                :key="item.id"
                @click="orderTabsChange(item)"
              >
                {{ item.title }}
              </div>
            </template>
          </div>
          <div class="r" v-if="orderStep == 1">
            <div class="history-list" v-if="orderTabsActive == 1">
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div class="send" v-if="orderTabsActive == 2">
              <div class="not-sign" v-if="!isSign && !isEnd">
                <h3>选择合同签署方式</h3>
                <p>
                  合同可以有效保障双方权益，减少争议纠纷<br />
                  我们为您提供以下两种合同签署方式，请选择适合您的合同签署方式
                </p>
                <div class="btn">
                  <el-button type="primary" @click="goSign(0)" plain
                    >上传本地线下合同</el-button
                  >
                  <el-button type="primary" @click="goSign(1)"
                    >发起电子合同</el-button
                  >
                </div>
              </div>
              <div class="not-sign" v-if="isEnd">
                <h3>合同签署完成</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button type="primary" @click="goSigns" plain
                    >查看合同</el-button
                  >
                </div>
              </div>
              <div class="not-sign" v-if="isSign && material == 0 && !isEnd">
                <h3>合同签署</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button type="primary" @click="goSigns"
                    >编辑电子合同</el-button
                  >
                </div>
              </div>
              <div class="not-sign" v-if="isSign && material == 1 && !isEnd">
                <h3>合同签署</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button type="primary" @click="goSigns"
                    >编辑本地线下合同</el-button
                  >
                </div>
              </div>
            </div>
            <div class="project" v-if="orderTabsActive == 3">
              <project
                @inits="inits"
                :yzTaskOrderPlanList="yzTaskOrderPlanList"
                :yzTaskMainId="detail.id"
                :detail="detail"
                :selectShoperYzTaskEvaluation="
                  detail.selectShoperYzTaskEvaluation
                "
              />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 4">
              <pay
                :yzTaskOrderPlanList="yzTaskOrderPlanList"
                @back="payBack"
                :yzTaskMainId="detail.id"
              />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 5">
              <supplement :yzTaskMainId="detail.id" />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 6">
              <invoice :yzTaskMainId="detail.id" />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 7">
              <refund :yzTaskMainId="detail.id" />
            </div>
          </div>
          <div class="r" v-if="orderStep != 1">
            <step-two
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              @inits="inits"
              :yzTaskMainId="detail.id"
              v-if="orderStep == 2"
            />
            <step-three
              @inits="inits"
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              :yzTaskMainId="detail.id"
              v-if="orderStep == 3"
            />
            <step-four
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              :yzTaskMainId="detail.id"
              v-if="orderStep == 4"
            />
          </div>
        </div>
      </div>
    </div>
    <contract-dialog
      ref="contract"
      :visible.sync="contractVisible"
      :detail="{ selectShoperYzTaskEvaluation: {}, ...detail }"
    />
  </div>
</template>
<script>
import supplement from "@/components/workbench/supplement";
import invoice from "@/components/workbench/invoice";
import refund from "@/components/workbench/refund";
import stepTwo from "@/components/workbench/step-two";
import stepThree from "@/components/workbench/step-three";
import stepFour from "@/components/workbench/step-four";
import pay from "@/components/workbench/pay";
import project from "@/components/workbench/project";
import upload from "@/components/servers/upload";
import contractDialog from "@/components/service/contract-dialog";
export default {
  components: {
    upload,
    contractDialog,
    supplement,
    invoice,
    refund,
    pay,
    project,
    stepTwo,
    stepThree,
    stepFour,
  },
  props: {
    detail: {
      type: Object,
      default: { selectShoperYzTaskEvaluation: {} },
    },
    active: {
      type: String | Number,
      default: 1,
    },
  },
  data() {
    return {
      yzTaskStepsJson: {
        1: "签署合同",
        2: "项目规划",
        3: "待付款",
        4: "已付款",
        5: "正在加工",
        6: "已发货",
        7: "产品验收",
        8: "订单完成",
      },
      statusClass: {
        1: "status1",
        2: "status1",
        3: "status2",
        4: "status2",
        5: "status3",
        6: "status3",
        7: "status4",
        8: "status4",
      },
      isEnd: false,
      material: 0,
      contractInfo: {},
      contractVisible: false,
      bizKey: "",
      activities: [
        {
          content: "雇主完善了一次需求",
          timestamp: "2018-04-12 20:46",
          color: "#2788ff",
        },
        {
          content: "需求公开招标",
          timestamp: "2018-04-03 20:46",
          color: "#2788ff",
        },
        {
          content: "雇主提交需求",
          timestamp: "2018-04-03 20:46",
          color: "#2788ff",
        },
        {
          content: "雇主完善了一次需求",
          timestamp: "2018-04-03 20:46",
          color: "#2788ff",
        },
        {
          content: "雇主完善了一次需求",
          timestamp: "2018-04-03 20:46",
          color: "#2788ff",
        },
        {
          content: "雇主完善了一次需求",
          timestamp: "2018-04-03 20:46",
          color: "#2788ff",
        },
      ],
      orderTabsActive: 1,
      orderTabsList: [
        { title: "需求动态", id: 1 },
        { title: "发起合同", id: 2 },
        { title: "项目规划", id: 3 },
        { title: "支付款项", id: 4 },
        { title: "资料补充", id: 5 },
        { title: "申请开票", id: 6 },
        { title: "申请退款", id: 7 },
      ],
      orderStep: 1,
      detailTabs: [
        { id: 1, title: "参与服务商" },
        { id: 2, title: "工作台" },
        { id: 3, title: "交易说明" },
        { id: 4, title: "交易规则" },
      ],
      isSign: false,
      yzTaskOrderPlanList: [],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    detailTabsActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
      },
    },
  },
  mounted() {},
  methods: {
    orderStepChange(i) {
      // this.orderStep = i;
    },
    payBack() {
      this.orderTabsActive = 3;
    },
    goSigns() {
      this.contractVisible = true;
      this.$refs.contract.inits();
    },
    getData() {
      if (!this.detail.selectShoperYzTaskEvaluation) return;
      this.$api
        .post(
          "/api/v1/getTaskContract",
          this.$common.getFormData({
            yzTaskEvaluationId: this.detail.selectShoperYzTaskEvaluation.id,
            yzTaskMainId: this.detail.id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.contractInfo = res.data;
            this.material = this.contractInfo.material;
            if (this.material == 0) {
              //线上，判断有没有serviceItems
              this.isSign = this.contractInfo.serviceItems != "";
            } else {
              //线下，判断listYTaskContractFileUpload
              this.isSign =
                this.contractInfo.listYTaskContractFileUpload &&
                this.contractInfo.listYTaskContractFileUpload.length;
            }
            if (
              this.contractInfo.taskUserStatus == 1 &&
              this.contractInfo.shoperUserStatus == 1
            ) {
              this.isEnd = true;
            }
            let preview = this.$route.query.preview;
            if (preview) {
              this.goSigns();
            }
            console.log(this.contractInfo);
          }
        });
    },
    goNext(i) {
      this.orderTabsList = [
        { title: "需求动态", id: 1 },
        { title: "发起合同", id: 2 },
        { title: "项目规划", id: 3 },
        { title: "支付款项", id: 4 },
        { title: "资料补充", id: 5 },
        { title: "申请开票", id: 6 },
        { title: "申请退款", id: 7 },
      ];
      if (i == 1) {
        this.orderTabsList.push({ title: "加工进程", id: 8 });
      }
      if (i == 2) {
        this.orderTabsList.push({ title: "加工进程", id: 8 });
        this.orderTabsList.push({ title: "质量检验", id: 9 });
      }
      if (i == 3) {
        this.orderTabsList.push({ title: "加工进程", id: 8 });
        this.orderTabsList.push({ title: "质量检验", id: 9 });
        this.orderTabsList.push({ title: "产品派送", id: 10 });
      }
    },
    inits() {
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.detail.id,
          })
        )
        .then((res) => {
          console.log("项目规划", res.data);
          let t = false;
          let s = false;
          this.yzTaskOrderPlanList = res.data.map((item) => {
            if (item.shoperUserSureExecuteStatus) {
              t = true;
            }

            if (item.taskUserSureExecuteStatus) {
              s = true;
            }
            return item;
          });
          if (t && s) {
            this.$api
              .post(
                "/api/v1/getListTaskOrderPlanStatistics",
                this.$common.getFormData({
                  yzTaskMainId: this.detail.id,
                })
              )
              .then((res) => {
                if (res.code == 1) {
                  if (!res.data.currentYzTaskOrderPlan) {
                    this.goNext(2);
                    this.$api
                      .post(
                        "/api/v1/isTaskOrderPlanQualityCount",
                        this.$common.getFormData({
                          taskOrderId: this.detail.id,
                        })
                      )
                      .then((res) => {
                        if (res.data) {
                          this.goNext(3);
                        }
                      });
                  } else {
                    this.goNext(1);
                  }
                }
              });
          }
        });
      let pay = this.$route.query.pay;
      let preview = this.$route.query.preview;
      this.$api.get("/api/v1/loginUserCheck").then((res) => {
        if (res.code == 1) {
          this.getData();
          if (pay && pay == 1) {
            this.detailTabsActive = 2;
            this.orderTabsActive = 4;
            $("body,html").animate(
              { scrollTop: $(".detail-tabs").offset().top - 100 },
              300
            );
          }
        } else if (preview || (pay && pay == 1)) {
          location.href = `${
            window.config.api
          }/a/login?__url=${encodeURIComponent(location.href)}`;
        }
      });
    },
    goSign(i) {
      if (i == 0) {
        //线上
        this.$confirm(`您确定要选择线上合同签署方式吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$api
            .post(
              "/api/v1/settingonlinecontact",
              this.$common.getFormData({
                id: this.contractInfo.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  if ((res.code = 1)) {
                    this.contractVisible = true;
                    this.$refs.contract.inits();
                  }
                },
              });
            });
        });
      } else {
        this.$confirm(`您确定要选择线下合同签署方式吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$api
            .post(
              "/api/v1/settingofflinecontact",
              this.$common.getFormData({
                id: this.contractInfo.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  if ((res.code = 1)) {
                    this.contractVisible = true;
                    this.$refs.contract.inits();
                  }
                },
              });
            });
        });
      }
    },
    upSuccess(files) {},
    tabsChange(item) {
      this.detailTabsActive = item.id;
    },
    orderTabsChange(item) {
      this.orderTabsActive = item.id;
      if (item.id == 8) {
        this.orderStep = 2;
      } else if (item.id == 9) {
        this.orderStep = 3;
      } else if (item.id == 10) {
        this.orderStep = 4;
      } else {
        this.orderStep = 1;
      }
    },
  },
};
</script>