<template>
  <div class="confract">
    <div class="title">网络服务交易合同</div>
    <div class="desc">
      <h3>合同说明：</h3>
      <p>
        本合同由雇主和知识工作者在线签订，请雇主和知识工作者仔细阅读合同内容，任何一方点击接受本合同，即意味着其已阅读本合同所有条款，并对本合同条款的含义及相应的法律后果已全部知晓并充分认可。雇主和知识工作者均点击确认本合同后，本合同即产生法律约束力。雇主和知识工作者可通过签署补充协议的方式对本合同条款进行变更或者补充，补充协议效力高于本合同。交易过程中如发生争议，本合同（包括补充协议）将作为争议解决的重要依据！
      </p>
      <p>
        本合同由《专用条款》、《参考条款》（如有）、《通用条款》组成，其效力按照由高到低排列，条款冲突时，以效力较高者优先适用。"
      </p>
    </div>
    <div class="btn" v-if="!preview">
      <el-button type="primary" @click="trun">修改合同签署方式</el-button>
      <el-button type="primary" @click="goView">官方合同模板</el-button>
    </div>
    <div class="sub-title">《专用条款》</div>
    <div class="true-sign" v-show="!preview">
      <el-form
        :model="signForm"
        ref="signForm"
        label-width="120px"
        inline
        class="sign-form"
      >
        <el-form-item
          v-show="isShoper == 2"
          prop="sign"
          label="雇主签名"
          :rules="[
            { required: true, message: '请填写雇主签名', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="signForm.sign"
            placeholder="请填写雇主签名"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="isShoper == 1"
          prop="sign"
          label="服务商签名"
          :rules="[
            { required: true, message: '请填写服务商签名', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="signForm.sign"
            placeholder="请填写服务商签名"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <fwxm
      ref="fwxmUp"
      :preview="preview"
      v-model="serviceItems"
      :listServiceItemsFileUpload.sync="listServiceItemsFileUpload"
      @success="adjunctSuccess"
    />
    <xmnr
      :form.sync="form"
      :preview="preview"
      :yzCashoutType="yzCashoutType"
      :yzPostCompanyType="yzPostCompanyType"
      :gyList="gyList"
      ref="xmnrForm"
    />
    <elses v-model="elseForm" :preview="preview" />
    <div class="sub-title">《通用条款》</div>
    <texts :preview="preview" />
    <signStatus :contractInfo="contractInfo" :detail="detail" v-if="preview" />
    <div class="footer-btn" v-if="!preview">
      <el-button type="primary" :loading="btnLoading" @click="save"
        >确定</el-button
      >
      <el-button type="primary" @click="goPreview" plain>预览</el-button>
      <el-button type="danger" @click="close" plain>取消</el-button>
    </div>
    <div class="footer-btn" v-if="preview && preview != 3">
      <el-button type="primary" @click="cancelPreview" plain
        >编辑合同</el-button
      >
    </div>
    <sign
      v-model="signShow"
      @success="signSuccess"
      :contractInfo="contractInfo"
    />
    <el-dialog
      :title="title"
      width="95vw"
      class="iframe-dialog"
      :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :lock-scroll="true"
    >
      <iframe :src="fileUrl" frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import fwxm from "../contract/fwxm";
import xmnr from "../contract/xmnr";
import elses from "../contract/else";
import texts from "../contract/text";
import sign from "../contract/sign";
import signStatus from "../contract/sign-status";
import Snowflake from "@/common/snowflake";
export default {
  components: { fwxm, xmnr, elses, texts, sign, signStatus },
  props: {
    detail: {
      default: { selectShoperYzTaskEvaluation: {} },
      type: Object,
    },
    contractInfo: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {
      title: "官方合同模板",
      fileUrl: "",
      dialogVisible: false,
      preview: false,
      signShow: false,
      elseForm: {
        taskUserRight: "",
        shoperUserRight: "",
        checkContent: "",
        breachContent: "",
      },
      form: {
        days: "",
        numbers: "",
        payType: "",
        slipingType: "",
        sax: "",
        totalPrice: "",
        yzTaskContractDataList: [],
      },
      serviceItems: "",
      uploadTaskContractServiceItemsFile: [],
      yzCashoutType: [],
      yzPostCompanyType: [],
      listServiceItemsFileUpload: [],
      gyList: [],
      btnLoading: false,
      signForm: {
        sign: "",
      },
      isShoper: -1,
    };
  },
  methods: {
    goView() {
      this.dialogVisible = true;
      let sid = "";
      if (window.config.api == "/papi") {
        sid = `&__sid=${window.config.sid}`;
      }
      this.$nextTick(() => {
        this.fileUrl = `https://www.yizao2025.com/a/file/download/1381209848417681409?preview=true&${sid}`;
      });
    },
    trun() {
      this.$confirm(`您确定要将合同签署方式改成线下吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .post(
            "/api/v1/settingofflinecontact",
            this.$common.getFormData({
              id: this.contractInfo.id,
            })
          )
          .then((res) => {
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {
                res.code == 1 ? this.$emit("inits") : null;
              },
            });
          });
      });
    },
    cancelPreview() {
      this.preview = false;
      $(".el-dialog__wrapper").animate({ scrollTop: 0 }, 300);
    },
    goPreview() {
      if (this.isShoper == 1) {
        this.contractInfo.shoperUserContactName = this.signForm.sign;
      } else if (this.isShoper == 2) {
        this.contractInfo.taskUserContactName = this.signForm.sign;
      }
      this.preview = true;
      $(".el-dialog__wrapper").animate({ scrollTop: 0 }, 300);
    },
    close() {
      this.$emit("close");
    },
    save() {
      this.$refs.signForm.validate((valid) => {
        if (valid) {
          if (this.serviceItems == "") {
            $(".el-dialog__wrapper").animate({ scrollTop: 300 }, 300);
            this.$message({
              message: "请输入服务项目内容！",
              type: "error",
            });
            return;
          }
          this.$refs.xmnrForm.submitForm(
            () => {
              this.btnLoading = true;
              this.$api
                .post(
                  this.isShoper == 2
                    ? "/api/v1/updateContactTaskUserContactName"
                    : "/api/v1/updateContactShoperUserContactName",
                  this.$common.getFormData({
                    id: this.contractInfo.id,
                    username: this.signForm.sign,
                  })
                )
                .then((res) => {
                  this.btnLoading = false;
                  if (res.code == 1) {
                    let arr = this.listServiceItemsFileUpload.filter((item) => {
                      return item.status == 1;
                    });
                    if (arr.length) {
                      this.goRemove(arr);
                    } else {
                      this.$refs.fwxmUp.submit();
                    }
                  } else {
                    this.$message({
                      message: res.message,
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  this.btnLoading = false;
                });
            },
            () => {
              $(".el-dialog__wrapper").animate({ scrollTop: 900 }, 300);
            }
          );
        } else {
          $(".el-dialog__wrapper").animate({ scrollTop: 0 }, 300);
          return false;
        }
      });
    },
    signSuccess() {
      this.$confirm(
        `请确认您已经填写了真实、完整的合同内容，若交易过程中双方发生争议纠纷，合同将是官方进行争议处理的重要依据！确认后对方将收到通知信息，对方确认后，合同正式生效。`,
        "发送合同",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.$api
            .post(
              "/api/v1/okContract",
              this.$common.getFormData({
                yzTaskEvaluationId: this.contractInfo.id,
                yzTaskMainId: this.detail.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  this.$emit("inits");
                },
              });
            });
        })
        .catch((err) => {
          this.$api
            .post(
              "/api/v1/cancelContract",
              this.$common.getFormData({
                yzTaskEvaluationId: this.contractInfo.id,
                yzTaskMainId: this.detail.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  this.$emit("inits");
                },
              });
            });
        });
    },
    goRemove(arr, i = 0) {
      this.$api
        .post(
          "/api/v1/unRemoveFileAndForm",
          this.$common.getFormData({
            bizKey: arr[i].bizKey,
            bizType: arr[i].bizType,
            fileUploadId: arr[i].id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (arr.length - 1 == i) {
              this.$refs.fwxmUp.submit();
            } else {
              i++;
              this.goRemove(arr, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        });
    },
    async inits() {
      this.$refs.fwxmUp.clear();
      //付款方式
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_cashout_type.json")
        .then((res) => {
          this.yzCashoutType = res;
        });
      //送货方式
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_post_company_type.json")
        .then((res) => {
          this.yzPostCompanyType = res;
        });
      //工艺
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_craft_types.json")
        .then((res) => {
          this.gyList = res;
        });
      let userInfo = this.$store.state.userInfo;
      console.log("用户信息：", userInfo);
      if (userInfo && userInfo.id) {
        if (userInfo.id == this.contractInfo.shopUserEsignatureId) {
          //服务商
          this.isShoper = 1;
          this.signForm.sign = this.contractInfo.shoperUserContactName;
        } else if (userInfo.id == this.contractInfo.taskUserEsignatureId) {
          //雇主
          this.isShoper = 2;
          this.signForm.sign = this.contractInfo.taskUserContactName;
        }
      }
      let {
        taskUserRight,
        shoperUserRight,
        checkContent,
        breachContent,
        days,
        numbers,
        payType,
        slipingType,
        sax,
        totalPrice,
        yzTaskContractDataList,
        serviceItems,
        listServiceItemsFileUpload,
      } = this.contractInfo;
      this.listServiceItemsFileUpload = listServiceItemsFileUpload;
      if (!yzTaskContractDataList.length) {
        yzTaskContractDataList = [
          {
            sort: 1,
            id: new Snowflake(1, 1, 0).nextId(),
            name: "",
            picture: "",
            material: "",
            numbers: "",
            days: "",
            yzCraftTypes: "",
            price: "",
            saxPrice: "",
            files: "",
            isNewRecord: true,
            status: 0,
            // listAllPicturesFileUpload: [],
          },
        ];
      }
      this.elseForm = {
        taskUserRight,
        shoperUserRight,
        checkContent,
        breachContent,
      };
      this.form = {
        days,
        numbers,
        payType,
        slipingType,
        sax,
        totalPrice,
        yzTaskContractDataList,
      };
      this.serviceItems = serviceItems;
      if (
        this.contractInfo.taskUserStatus == 1 &&
        this.contractInfo.shoperUserStatus == 1
      ) {
        this.preview = 3;
      }
    },
    goSave() {
      this.$api
        .post(
          "/api/v1/saveTaskContract",
          this.$common.getFormData({
            ...this.form,
            serviceItems: this.serviceItems,
            ...this.elseForm,
            isNewRecord: true,
            yzTaskEvaluationId: this.detail.selectShoperYzTaskEvaluation.id,
            yzTaskMainId: this.detail.id,
            id: this.contractInfo.id,
          })
        )
        .then((res) => {
          this.$emit("inits");
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
            onClose: () => {
              res.code == 1
                ? this.contractSaveSuccess()
                : (this.btnLoading = false);
            },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    contractSaveSuccess() {
      this.signShow = true;
      this.btnLoading = false;
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadTaskContractServiceItemsFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskMainId: this.detail.id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.goSave();
            } else {
              i++;
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
          this.btnLoading = false;
        });
    },
    adjunctSuccess(files) {
      this.btnLoading = true;
      if (files) {
        this.goAbout(files);
      } else {
        this.goSave();
      }
    },
  },
  mounted() {
    let preview = this.$route.query.preview;
    if (preview) {
      this.preview = true;
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/contract.scss";
</style>