<template>
  <div class="step">
    <div class="content">
      <div class="step-main">
        <div class="time" v-if="!isOver">
          投标剩余时间：<span>{{ overDate.d }}</span
          >天<span>{{ overDate.h }}</span
          >时<span>{{ overDate.m }}</span
          >分<span>{{ overDate.s }}</span
          >秒
        </div>
        <el-steps :active="yzTaskSteps" align-center>
          <el-step
            v-for="item in steps"
            :key="item.id"
            :title="item.title"
            :description="item.date"
          ></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    overDate: {
      type: Object,
      default: { d: 0, h: 0, m: 0, s: 0 },
    },
    detail: {
      type: Number | String,
      default: 0,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    yzTaskSteps() {
      let step = 1;
      this.steps[0].date = this.detail.createDate;
      if (
        this.detail.listYzTaskEvaluation &&
        this.detail.listYzTaskEvaluation.length
      ) {
        step = 2;
        this.steps[1].date = this.detail.listYzTaskEvaluation[0].createDate;
        this.detail.listYzTaskEvaluation.forEach((item) => {
          if (this.detail.selectShoperYzTaskEvaluation) {
            step = 3;
            this.steps[2].date = item.updateDate;
          }
        });
      }
      if (this.detail.selectShoperYzTaskEvaluation) {
        step = 4;
        this.steps[3].date = this.detail.selectShoperYzTaskEvaluation.updateDate;
      }
      return step;
    },
  },
  data() {
    return {
      steps: [
        { id: 1, title: "发布需求", date: "" },
        { id: 2, title: "服务商报名", date: "" },
        { id: 3, title: "商家报价", date: "" },
        { id: 4, title: "中标公示", date: "" },
        { id: 5, title: "工作中", date: "" },
        { id: 6, title: "完成工作，验收付款", date: "" },
      ],
      yzTaskStepJson: {},
    };
  },
  mounted() {
    this.$sapi.get("/api/v1/getDictDataList-yz_task_step.json").then((res) => {
      res.forEach((item) => {
        this.yzTaskStepJson[item.dictValue] = item.dictLabel;
      });
    });
  },
  methods: {
    inits() {},
  },
};
</script>