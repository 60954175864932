<template>
  <el-dialog
    title="签章设置"
    :visible.sync="visible"
    width="700px"
    class="sign-dialog"
    :before-close="handleClose"
    :modal-append-to-body="true"
    :append-to-body="true"
    :lock-scroll="true"
  >
    <div class="sign-main">
      <div class="title">
        签章设置 <span @click="handleClose">返回 >></span>
      </div>
      <div class="b">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="手绘" name="1"></el-tab-pane>
          <el-tab-pane label="上传" name="2"></el-tab-pane>
          <el-tab-pane label="我的签章" name="3"></el-tab-pane>
        </el-tabs>
        <div class="code" v-show="activeName == '1'">
          <vue-esign
            class="e-sign-main"
            ref="esign"
            :width="400"
            :height="200"
            :isCrop="true"
            :lineWidth="6"
            lineColor="#000000"
            :bgColor.sync="bgColor"
          />
          <div class="btns">
            <el-button
              type="primary"
              :loading="signLoad"
              @click="handleGenerate"
              >使用该签章</el-button
            >
            <el-button type="primary" @click="handleReset" plain
              >重置画板</el-button
            >
          </div>
        </div>
        <div class="up-image" v-show="activeName == '2'">
          <upload
            uploadType="image"
            mode="sign"
            ref="upload"
            format="png/jpg/svg"
            :fileName.sync="fileName"
            @success="upSuccess"
            @checkSuccess="checkSuccess"
            :multiple="false"
          />
          <div class="btns" v-if="fileName">
            <el-button type="primary" @click="save">使用该签章</el-button>
            <el-button type="primary" @click="clear" plain>取消</el-button>
          </div>
        </div>
        <div class="sign-list" v-show="activeName == '3'">
          <div
            class="sign-item"
            v-for="item in signList"
            :key="item.id"
            @click="checkSign(item)"
          >
            <el-image :src="item.fileUrl" fit="cover"></el-image>
            <p v-if="!signLoad" >选择此签名</p>
            <p v-if="signLoad" ><text class="el-icon-loading"></text>请稍后</p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import vueEsign from "vue-esign";
import upload from "@/components/servers/upload";
import md5 from "js-md5";
export default {
  components: { upload, vueEsign },
  data() {
    return {
      activeName: "1",
      fileName: "",
      bgColor: "",
      signLoad: false,
      signList: [],
    };
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    contractInfo: {
      default: {},
      type: Object,
    },
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    inits() {
      this.$api.post("/api/v1/genCurrentUserEsignature").then((res) => {
        if (res.data && res.data.listUploadFile) {
          this.signList = res.data.listUploadFile.map((item) => {
            item.fileUrl = item.fileUrl.replace(
              "https://yizao.oss-accelerate.aliyuncs.com",
              window.config.sapi
            );
            console.log(item.fileUrl);
            return item;
          });
        }
      });
    },
    //将base64转换为文件对象
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      //转换成file对象
      return new File([u8arr], filename, { type: mime });
      //转换成成blob对象
      //return new Blob([u8arr],{type:mime});
    },
    handleReset() {
      this.$refs.esign.reset();
    },
    checkSign(item) {
      this.signLoad = true;
      this.$api
        .post(
          "/api/v1/uploadYzUserImageEsignature",
          this.$common.getFormData({
            fileUploadId: item.id,
            yzTaskContractId: this.contractInfo.id,
          })
        )
        .then((res) => {
          this.signLoad = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
            onClose: () => {
              if (res.code == 1) {
                this.visible = false;
                this.inits();
                this.$emit("success", res);
              }
            },
          });
        })
        .catch((err) => {
          this.signLoad = false;
        });
    },
    handleGenerate() {
      this.signLoad = true;
      this.$refs.esign
        .generate()
        .then((res) => {
          let file = this.dataURLtoFile(
            res,
            `${new Date().getTime()}_${this.contractInfo.id}.png`
          );
          let params = {
            __ajax: "json",
            fileMd5: md5(file.name),
            fileName: file.name,
            bizType: "yzTaskMain_file",
            uploadType: "image",
            file,
          };
          this.$api
            .post("/a/file/upload.json", this.$common.getFormData(params))
            .then((res) => {
              this.signLoad = false;
              if (res.result == "true") {
                this.$api
                  .post(
                    "/api/v1/uploadYzUserHandEsignature",
                    this.$common.getFormData({
                      fileUploadId: res.fileUpload.id,
                      yzTaskContractId: this.contractInfo.id,
                    })
                  )
                  .then((res) => {
                    this.$message({
                      message: res.message,
                      type: res.code == 1 ? "success" : "error",
                      duration: 1500,
                      onClose: () => {
                        if (res.code == 1) {
                          this.visible = false;
                          this.inits();
                          this.$emit("success", res);
                        }
                      },
                    });
                  });
              }
            })
            .catch((err) => {
              this.signLoad = false;
            });
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    clear() {
      this.$refs.upload.clear();
    },
    save() {
      this.$refs.upload.submit();
    },
    upSuccess(files) {
      this.$api
        .post(
          "/api/v1/uploadYzUserImageEsignature",
          this.$common.getFormData({
            fileUploadId: files[0].fileUploadId,
            yzTaskContractId: this.contractInfo.id,
          })
        )
        .then((res) => {
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
            onClose: () => {
              if (res.code == 1) {
                this.visible = false;
                this.$emit("success", res);
              }
            },
          });
        });
    },
    checkSuccess(files) {},
    handleClick() {},
    handleClose() {
      this.visible = false;
    },
  },
  mounted() {
    this.inits();
  },
};
</script>
