<template>
  <div class="item">
    <div class="item-title">
      <i class="iconfont icon-zhengpinbaozhangduigou"></i>服务项目
    </div>
    <div class="input-main">
      <p v-if="!preview">
        提示：雇主与知识工作者应当充分沟通，并明确服务项目的具体内容,如项目描述、产品功能描述、服务要求等
      </p>
      <el-input
        v-if="!preview"
        type="textarea"
        v-model="text"
        placeholder="请输入内容"
      ></el-input>
      <div class="text-main" v-if="preview">
        {{ text }}
      </div>
      <upload
        :preview="preview"
        mode="adjunct"
        ref="upload"
        :bizKey="bizKey"
        :fileName.sync="filesName"
        @success="upSuccess"
        :filelist="listServiceItemsFileUpload"
        :fileData.sync="fileData"
        @removeFile="removeFile"
      />
      <p class="note" v-if="!preview">
        注：建议您上传一些必要的补充或参考文件，如：详细需求说明文档、设计委托书、规划要求说明书、立项文案等。最多可添加五个
        附件，每个大小不超过10M。 上传遇到问题?
      </p>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    value: {
      default: "",
      type: String,
    },
    preview: {
      default: false,
      type: Boolean|Number,
    },
    listServiceItemsFileUpload: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      bizKey: "",
      filesName: "",
      fileData: [],
    };
  },
  methods: {
    clear() {
      this.$refs.upload.clear();
    },
    removeFile(item) {
      this.listServiceItemsFileUpload = this.listServiceItemsFileUpload.map(
        (items) => {
          if (items.id == item.id) {
            items.status = 1;
          }
          return items;
        }
      );
    },
    submit() {
      if (this.filesName) {
        this.$refs.upload.submit();
      } else {
        this.upSuccess();
      }
    },
    upSuccess(files) {
      this.$emit("success", files);
    },
  },
};
</script>
