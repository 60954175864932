<template>
  <el-form
    :model="supplementForm"
    ref="supplementForm"
    label-position="top"
    class="pay-form"
    size="small"
  >
    <el-form-item
      prop="description"
      label="补充需求"
      :rules="[
        {
          required: true,
          message: '请补充需求',
          trigger: 'blur',
        },
      ]"
    >
      <el-input
        type="textarea"
        v-model="supplementForm.description"
        rows="10"
        placeholder="请补充需求"
      ></el-input>
    </el-form-item>
    <el-form-item
      prop="filesName"
      label="附件上传"
      :rules="[
        {
          required: true,
          message: '请上传附件',
          trigger: 'change',
        },
      ]"
    >
      <upload
        ref="supplementUpLoad"
        :fileName.sync="supplementForm.filesName"
        @success="supplementSuccess"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="supplementLoad" @click="goSupplement"
        >确定</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import upload from "@/components/servers/upload";
import Snowflake from "@/common/snowflake";
export default {
  components: { upload },
  props: {
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  data() {
    return {
      supplementLoad: false,
      taskSupplementId: new Snowflake(1, 1, 0).nextId(),
      supplementForm: {
        description: "",
        filesName: "",
      },
    };
  },
  methods: {
    //上传补充附件
    goSupplement() {
      this.$refs.supplementForm.validate((valid) => {
        if (valid) {
          this.supplementLoad = true;
          if (this.supplementForm.filesName) {
            this.$refs.supplementUpLoad.submit();
          } else {
            this.goSaveSupplement();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交补充资料
    goSaveSupplement() {
      this.$api
        .post(
          "/api/v1/commitYzTaskSupplement",
          this.$common.getFormData({
            description: this.supplementForm.description,
            yzTaskMainId: this.yzTaskMainId,
            taskSupplementId: this.taskSupplementId,
          })
        )
        .then((res) => {
          this.supplementLoad = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
            onClose: () => {
              if (res.code == 1) {
                this.supplementForm = {
                  description: "",
                  filesName: "",
                };
                this.$refs.supplementUpLoad.clear();
              }
            },
          });
        })
        .catch((err) => {
          this.supplementLoad = false;
        });
    },
    //关联需求资料
    goAboutSupplement(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadTaskSupplementFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskMainId: this.yzTaskMainId,
            taskSupplementId: this.taskSupplementId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.goSaveSupplement(res);
            } else {
              i++;
              //未结束，继续关联
              this.goAboutSupplement(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.supplementLoad = false;
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
          this.supplementLoad = false;
        });
    },
    //需求资料上传成功
    supplementSuccess(files) {
      if (files) {
        this.goAboutSupplement(files);
      } else {
        this.goSaveSupplement();
      }
    },
  },
};
</script>