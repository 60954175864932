var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-two"},[_c('div',{staticClass:"steps"},[_c('el-steps',{attrs:{"active":_vm.stepTwoActive,"align-center":""}},_vm._l((_vm.stepTwoList),function(item){return _c('el-step',{key:item.id,attrs:{"title":_vm.stepJson[item.step] || item.step}})}),1)],1),_c('div',{staticClass:"table-list"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoad),expression:"tableLoad"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableList,"border":"","size":"small"}},[_c('el-table-column',{attrs:{"prop":"date","align":"center","label":"项目进度"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.stepJson[scope.row.step] || scope.row.step)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"workContent","align":"center","label":"项目内容"}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"阶段审核"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              (!scope.row.listResultFiles ||
                !scope.row.listResultFiles.length) &&
              _vm.hasShop &&
              _vm.commonStatus.currentYzTaskOrderPlan
            )?_c('upload',{ref:"upload",attrs:{"mode":"craft","btnText":"请上传阶段审核资料","format":"stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi","uploadType":"all","keyName":scope.row.id},on:{"checkSuccess":_vm.checkSuccess,"success":_vm.upSuccess}}):_vm._e(),(
              (!scope.row.listResultFiles ||
                !scope.row.listResultFiles.length) &&
              _vm.hasShop &&
              !_vm.commonStatus.currentYzTaskOrderPlan
            )?_c('p',{staticClass:"step-status s5"},[_vm._v(" 未上传 ")]):_vm._e(),(
              (!scope.row.listResultFiles ||
                !scope.row.listResultFiles.length) &&
              !_vm.hasShop
            )?_c('p',{staticClass:"step-status s5"},[_vm._v(" 服务商未上传 ")]):_vm._e(),(
              scope.row.listResultFiles &&
              scope.row.listResultFiles.length &&
              _vm.hasShop
            )?_c('p',{staticClass:"step-status"},[_vm._v(" 已上传 ")]):_vm._e(),(
              scope.row.listResultFiles &&
              scope.row.listResultFiles.length &&
              !_vm.hasShop
            )?_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover"}},[_c('el-table',{attrs:{"data":scope.row.listResultFiles,"height":"260"}},[_c('el-table-column',{attrs:{"width":"200","property":"fileName","label":"文件名称"}}),_c('el-table-column',{attrs:{"width":"100","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-download"},on:{"click":function($event){return _vm.goDown(scope.row)}}},[_vm._v("下载")])]}}],null,true)})],1),_c('el-button',{attrs:{"slot":"reference","icon":"el-icon-download","type":"primary","size":"mini"},slot:"reference"},[_vm._v("下载审核资料")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"问题反馈"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              (!scope.row.listQuestionFiles ||
                !scope.row.listQuestionFiles.length) &&
              !_vm.hasShop &&
              _vm.commonStatus.currentYzTaskOrderPlan
            )?_c('upload',{ref:"upload",attrs:{"mode":"craft","btnText":"请上传反馈资料","format":"stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi","uploadType":"all","keyName":scope.row.id},on:{"checkSuccess":_vm.checkSuccess,"success":_vm.upSuccess}}):_vm._e(),(
              (!scope.row.listQuestionFiles ||
                !scope.row.listQuestionFiles.length) &&
              !_vm.hasShop &&
              !_vm.commonStatus.currentYzTaskOrderPlan
            )?_c('p',{staticClass:"step-status s5"},[_vm._v(" 未上传 ")]):_vm._e(),(
              (!scope.row.listQuestionFiles ||
                !scope.row.listQuestionFiles.length) &&
              _vm.hasShop
            )?_c('p',{staticClass:"step-status s5"},[_vm._v(" 需求方未上传 ")]):_vm._e(),(
              scope.row.listQuestionFiles &&
              scope.row.listQuestionFiles.length &&
              !_vm.hasShop
            )?_c('p',{staticClass:"step-status"},[_vm._v(" 已上传 ")]):_vm._e(),(
              scope.row.listQuestionFiles &&
              scope.row.listQuestionFiles.length &&
              _vm.hasShop
            )?_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover"}},[_c('el-table',{attrs:{"data":scope.row.listQuestionFiles,"height":"260"}},[_c('el-table-column',{attrs:{"width":"200","property":"fileName","label":"文件名称"}}),_c('el-table-column',{attrs:{"width":"100","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-download"},on:{"click":function($event){return _vm.goDown(scope.row)}}},[_vm._v("下载")])]}}],null,true)})],1),_c('el-button',{attrs:{"slot":"reference","icon":"el-icon-download","type":"primary","size":"mini"},slot:"reference"},[_vm._v("下载反馈资料")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"状态确认"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{class:[
              'step-status',
              's' + scope.row.yzTaskOrderPlanOperateStatus ]},[_vm._v(" "+_vm._s(_vm.statusJson[scope.row.yzTaskOrderPlanOperateStatus] || scope.row.yzTaskOrderPlanOperateStatus)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"endTime","align":"center","label":"预计完成时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.endTime)?_c('p',[_vm._v(" "+_vm._s(scope.row.endTime.split(" ")[0])+" ")]):_vm._e()]}}])})],1)],1),(_vm.commonStatus.currentYzTaskOrderPlan)?_c('div',{staticClass:"f-btn"},[(_vm.hasShop)?_c('el-button',{attrs:{"type":"success","size":"small","icon":"el-icon-message","loading":_vm.load2},on:{"click":_vm.goTip}},[_vm._v("提示客户确认")]):_vm._e(),(_vm.hasShop && _vm.commonStatus.currentYzTaskOrderPlan.commitMoney == 0)?_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-video-play","loading":_vm.load1},on:{"click":_vm.goNext}},[_vm._v("申请进入下一环节")]):_vm._e(),(!_vm.hasShop)?_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-message","loading":_vm.load2},on:{"click":_vm.goTip}},[_vm._v("提示工厂上传阶段成果")]):_vm._e(),(!_vm.hasShop)?_c('el-button',{attrs:{"type":"success","size":"small","icon":"el-icon-circle-check","loading":_vm.load1},on:{"click":_vm.goNext}},[_vm._v("确认执行")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }