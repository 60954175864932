<template>
  <div class="offer" v-if="detail.currentShoperYzTaskEvaluation">
    <div class="content">
      <div class="offer-main">
        <el-form
          :model="offerForm"
          label-position="top"
          size="small"
          ref="offerForm"
          class="offer-form"
        >
          <el-row :gutter="20">
            <el-col :span="3">
              <el-form-item
                prop="singilePrice"
                label="总金额（元）"
                :rules="[
                  { required: true, message: '请输入总金额', trigger: 'blur' },
                ]"
              >
                <el-input
                  type="number"
                  v-model="offerForm.singilePrice"
                  placeholder="请输入总金额"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item
                prop="days"
                label="工期（天）"
                :rules="[
                  { required: true, message: '请输入工期', trigger: 'blur' },
                ]"
              >
                <el-input
                  type="number"
                  v-model="offerForm.days"
                  placeholder="请输入工期"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-form-item
                prop="isFax"
                label="含税"
                style="text-align: center"
              >
                <el-checkbox v-model="offerForm.isFax"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-form-item
                prop="faxValue"
                label="税点（%）"
                :rules="[
                  {
                    required: offerForm.isFax,
                    message: '请输入税点',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  type="number"
                  :disabled="!offerForm.isFax"
                  v-model="offerForm.faxValue"
                  placeholder="请输入税点"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item prop="totalPrice" label="含税金额（元）">
                <el-input
                  type="number"
                  readonly
                  v-model="totalPrice"
                  placeholder="含税金额"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item
                prop="filesName"
                label="上传报价"
                :rules="[
                  { required: true, message: '请上传报价', trigger: 'change' },
                ]"
              >
                <template slot="label">
                  上传报价
                  <a
                    class="link"
                    href="http://"
                    target="_blank"
                    download="《报价模板》.doc"
                    >下载《报价模板》</a
                  >
                </template>
                <upload
                  mode="btn"
                  ref="upload"
                  :bizKey="bizKey"
                  :fileName.sync="offerForm.filesName"
                  @success="upSuccess"
                  :multiple="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item class="btn" label="操作" prop="price">
                <el-button type="primary" @click="submit" :loading="loading"
                  >提交报价</el-button
                >
                <!-- <el-button type="primary" @click="bench">进入工作台</el-button> -->
                <el-button
                  icon="el-icon-star-off"
                  @click="goStar"
                  :loading="loading2"
                  >收藏</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  components: { upload },
  data() {
    return {
      bizKey: "",
      offerForm: {
        days: "",
        faxValue: "",
        filesName: "",
        singilePrice: "",
        totalPrice: "",
        isFax: false,
      },
      loading: false,
      loading2: false,
    };
  },
  computed: {
    totalPrice() {
      let { floatAdd, floatSub, floatMul, floatDiv } = this.$common;
      let { days, faxValue, filesName, singilePrice, isFax } = this.offerForm;
      let total = isFax
        ? floatMul(floatAdd(1, floatDiv(faxValue, 100)), singilePrice)
        : singilePrice;
      this.offerForm.totalPrice = total;
      return total;
    },
  },
  watch: {
    ["offerForm.filesName"](newVal, oldVal) {
      this.$refs.offerForm.validateField(["filesName"], (err) => {});
    },
  },
  methods: {
    goStar() {
      this.$api.post(
        "/api/v1/yzCollection",
        this.$common
          .getFormData({
            collectionId: this.detail.id,
            yzCollectionTyp: 2,
          })
          .then((res) => {
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {
                this.$emit("inits");
              },
            });
          })
      );
    },
    upSuccess(files) {
      let {
        days,
        faxValue,
        filesName,
        singilePrice,
        totalPrice,
        isFax,
      } = this.offerForm;
      let params = {
        fileUploadId: files[0].fileUploadId,
        yzTaskMainId: this.detail.id,
        yzTaskEvaluationId: this.detail.currentShoperYzTaskEvaluation.id,
        days,
        faxValue,
        filesName,
        singilePrice,
        totalPrice,
        isFax: isFax ? 1 : 0,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/uploadPartakeTaskMain", this.$common.getFormData(params))
        .then((res) => {
          this.loading = false;
          if (res.code == "1") {
            this.$api
              .post(
                "/api/v1/updateBaojiaCountCountAddOne",
                this.$common.getFormData({ id: this.detail.id })
              )
              .then((res) => console.log(res.message));
            this.$message({
              message: res.message,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.$emit("inits");
                this.$refs.upload.clear();
                this.offerForm = {
                  days: "",
                  faxValue: "",
                  filesName: "",
                  singilePrice: "",
                  totalPrice: "",
                  isFax: false,
                };
              },
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.offerForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$refs.upload.submit();
        }
      });
    },
    bench() {
      this.$emit("bench");
    },
  },
};
</script>