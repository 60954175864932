<template>
  <div class="item">
    <div class="item-title">
      <i class="iconfont icon-zhengpinbaozhangduigou"></i>项目内容与周期
    </div>
    <div :class="['file-list', preview ? 'on' : '']">
      <el-form
        :model="forms"
        ref="tablelists"
        label-width="100px"
        size="small"
        class="tablelists-form"
      >
        <el-table
          size="small"
          :data="forms.yzTaskContractDataList"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="sort" align="center" label="编号" width="60">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.sort`"
              >
                <el-input
                  v-if="!preview"
                  size="small"
                  v-model="scope.row.sort"
                ></el-input>
                <p v-if="preview">{{ scope.row.sort }}</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="零件名称">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.name`"
                :rules="{
                  required: true,
                  message: '请输入零件名称',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!preview"
                  placeholder="请输入零件名称"
                  v-model="scope.row.name"
                ></el-input>
                <p v-if="preview">{{ scope.row.name }}</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="picture" align="center" label="图片">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.picture`"
                :rules="{
                  required: true,
                  message: '请上传图片',
                  trigger: 'change',
                }"
              >
                <upload
                  v-show="!preview"
                  uploadType="image"
                  mode="image"
                  :ref="'upload' + scope.row.id"
                  format="png/jpg/svg"
                  :bizKey="bizKey"
                  :keyName="scope.row.id"
                  :fileName.sync="scope.row.picture"
                  @success="upSuccess"
                  @checkSuccess="checkSuccess"
                  :multiple="false"
                  :filelist="
                    scope.row.picture ? [{ fileUrl: scope.row.picture }] : []
                  "
                />
                <el-input
                  style="display: none"
                  placeholder="请上传图片"
                  v-model="scope.row.picture"
                ></el-input>
                <p v-if="preview">
                  <el-image
                    class="file-image"
                    v-if="scope.row.picture"
                    :src="scope.row.picture"
                    fit="cover"
                  ></el-image>
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="material" align="center" label="材料">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.material`"
                :rules="{
                  required: true,
                  message: '请输入材料名称',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!preview"
                  placeholder="请输入材料名称"
                  v-model="scope.row.material"
                ></el-input>
                <p v-if="preview">{{ scope.row.material }}</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="numbers" align="center" label="数量">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.numbers`"
                :rules="{
                  required: true,
                  message: '请输入数量',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!preview"
                  type="number"
                  placeholder="请输入数量"
                  v-model="scope.row.numbers"
                >
                  <span slot="suffix" class="unit">件</span>
                </el-input>
                <p v-if="preview">{{ scope.row.numbers }}件</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="yzCraftTypes" align="center" label="工艺">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.yzCraftTypes`"
                :rules="{
                  required: true,
                  message: '请选择工艺',
                  trigger: 'change',
                }"
              >
                <el-select
                  v-model="scope.row.yzCraftTypes"
                  placeholder="请选择"
                  v-if="!preview"
                >
                  <el-option
                    v-for="item in gyList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <p v-if="preview">
                  {{ gyName(scope.row.yzCraftTypes) }}
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="price" align="center" label="未税单价">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.price`"
                :rules="{
                  required: true,
                  message: '请输入未税单价',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!preview"
                  type="number"
                  placeholder="请输入未税单价"
                  v-model="scope.row.price"
                >
                  <span slot="suffix" class="unit">元</span>
                </el-input>
                <p v-if="preview">{{ scope.row.price }}元</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="saxPrice" align="center" label="含税单价">
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskContractDataList.${scope.$index}.saxPrice`"
                :rules="{
                  required: true,
                  message: '请输入含税单价',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!preview"
                  type="number"
                  placeholder="请输入含税单价"
                  v-model="scope.row.saxPrice"
                >
                  <span slot="suffix" class="unit">元</span>
                </el-input>
                <p v-if="preview">{{ scope.row.saxPrice }}元</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="sort"
            align="center"
            label="操作"
            v-if="!preview"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-button
                  size="small"
                  :disabled="scope.$index == 0"
                  type="danger"
                  @click="del(scope.row)"
                >
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="btn">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="add"
          v-if="!preview"
          >添加新零件</el-button
        >
        <div v-if="preview"></div>
        <span>合计：{{ totalPrice }}元</span>
      </div>
      <div class="form-main">
        <el-form
          :model="forms"
          ref="forms"
          label-width="100px"
          class="forms-dynamic"
          label-suffix="："
          size="small"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item
                prop="days"
                label="加工周期"
                :rules="[
                  {
                    required: true,
                    message: '请输入加工周期',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-if="!preview"
                  type="number"
                  v-model="forms.days"
                  placeholder="请输入加工周期"
                >
                  <span slot="suffix" class="unit">天</span>
                </el-input>
                <p v-if="preview">{{ forms.days }}天</p>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="payType"
                label="付款方式"
                :rules="[
                  {
                    required: true,
                    message: '请选择付款方式',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-if="!preview"
                  v-model="forms.payType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in yzCashoutType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <p v-if="preview">{{ yzCashoutTypeName(forms.payType) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="slipingType"
                label="送货方式"
                :rules="[
                  {
                    required: true,
                    message: '请选择送货方式',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-if="!preview"
                  v-model="forms.slipingType"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in yzPostCompanyType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <p v-if="preview">
                  {{ yzPostCompanyTypeName(forms.slipingType) }}
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                prop="sax"
                label="税费"
                :rules="[
                  { required: true, message: '请输入税费', trigger: 'blur' },
                ]"
              >
                <el-input
                  v-if="!preview"
                  type="number"
                  v-model="forms.sax"
                  placeholder="请输入税费"
                >
                  <span slot="suffix" class="unit">%</span>
                </el-input>
                <p v-if="preview">{{ forms.sax }}%</p>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
import Snowflake from "@/common/snowflake";
export default {
  components: { upload },
  props: {
    form: {
      default: {},
      type: Object,
    },
    preview: {
      default: false,
      type: Boolean|Number,
    },
    yzCashoutType: {
      default: [],
      type: Array,
    },
    yzPostCompanyType: {
      default: [],
      type: Array,
    },
    gyList: {
      default: [],
      type: Array,
    },
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.form.yzTaskContractDataList.forEach((item) => {
        total = this.$common.floatAdd(total, item.saxPrice);
      });
      this.forms.totalPrice = total;
      return total;
    },
    forms: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("update:form", val);
      },
    },
  },
  data() {
    return {
      bizKey: "",
      filesName: "",
      fileItem: {},
    };
  },
  methods: {
    gyName(item) {
      let obj = this.gyList.find((items) => items.dictValue == item);
      if (obj) {
        return obj.dictLabel;
      } else {
        return "";
      }
    },
    yzCashoutTypeName(item) {
      let obj = this.yzCashoutType.find((items) => items.dictValue == item);
      if (obj) {
        return obj.dictLabel;
      } else {
        return "";
      }
    },
    yzPostCompanyTypeName(item) {
      let obj = this.yzPostCompanyType.find((items) => items.dictValue == item);
      if (obj) {
        return obj.dictLabel;
      } else {
        return "";
      }
    },
    tableRowClassName(row, index) {
      if (row.row.status == "1") {
        return "hidden-row";
      }
      return "";
    },
    submitForm(callback, error) {
      this.$refs["tablelists"].validate((valid) => {
        if (valid) {
          this.$refs["forms"].validate((valid) => {
            if (valid) {
              callback ? callback() : null;
            } else {
              error ? error() : null;
              return false;
            }
          });
        } else {
          error ? error() : null;
          return false;
        }
      });
    },
    del(row) {
      if (row.isNewRecord) {
        this.forms.yzTaskContractDataList = this.forms.yzTaskContractDataList.filter(
          (item) => {
            return item.id != row.id;
          }
        );
        let i = 1;
        this.forms.yzTaskContractDataList = this.forms.yzTaskContractDataList.map(
          (item, index) => {
            if (item.status == 1) {
              item.sort = 9999;
            } else {
              item.sort = i;
              i++;
            }
            return item;
          }
        );
      } else {
        let i = 1;
        this.forms.yzTaskContractDataList = this.forms.yzTaskContractDataList.map(
          (item) => {
            if (item.id == row.id) {
              item.status = 1;
              item.sort = 9999;
            } else {
              item.sort = i;
              i++;
            }
            return item;
          }
        );
      }
      this.forms.numbers = this.forms.yzTaskContractDataList.length;
    },
    checkSuccess(files) {
      this.$refs["upload" + files[0].keyName].submit();
    },
    goAbout() {
      this.$api
        .post(
          "/api/v1/uploadYzTaskContractDataFile",
          this.$common.getFormData({
            yzTaskContractDataId: this.fileItem.keyName,
            fileUploadId: this.fileItem.fileUploadId,
          })
        )
        .then((res) => {
          console.log(res);
        });
    },
    upSuccess(files) {
      console.log(files[0].res);
      this.fileItem = files[0];
      this.forms.yzTaskContractDataList = this.forms.yzTaskContractDataList.map(
        (item) => {
          if (this.fileItem.keyName == item.id) {
            //路径赋值
            item.picture = this.fileItem.res.fileUpload.fileUrl;
            //关联
            this.goAbout();
          }
          return item;
        }
      );
    },
    add() {
      this.forms.yzTaskContractDataList.push({
        sort: this.forms.yzTaskContractDataList.length + 1,
        id: new Snowflake(this.forms.yzTaskContractDataList.length + 1, 1, 0).nextId(),
        name: "",
        picture: "",
        material: "",
        numbers: "",
        days: "",
        yzCraftTypes: "",
        price: "",
        saxPrice: "",
        files: "",
        isNewRecord: true,
        status: 0,
        // listAllPicturesFileUpload: [],
      });
      let i = 1;
      this.forms.yzTaskContractDataList = this.forms.yzTaskContractDataList.map(
        (item) => {
          if (item.status == 1) {
            item.sort = 9999;
          } else {
            item.sort = i;
            i++;
          }
          return item;
        }
      );
      this.forms.numbers = this.forms.yzTaskContractDataList.length;
    },
  },
};
</script>
