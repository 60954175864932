<template>
  <el-form :model="invoiceForm" ref="invoiceForm" class="pay-form" size="small">
    <el-form-item
      prop="description"
      label="申请开票"
      :rules="[
        {
          required: true,
          message: '请输入公司税务开票信息',
          trigger: 'blur',
        },
      ]"
    >
      <el-input
        type="textarea"
        v-model="invoiceForm.description"
        rows="5"
        placeholder="请输入公司税务开票信息"
      ></el-input>
    </el-form-item>
    <el-row>
      <el-col :span="12">
        <el-form-item
          prop="money"
          label="开票金额（元）"
          :rules="[
            {
              required: true,
              message: '请输入开票金额',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="number"
            v-model="invoiceForm.money"
            placeholder="请输入开票金额"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-form-item
          prop="yzInvoiceType"
          label="开票类型"
          :rules="[
            {
              required: true,
              message: '请选择开票类型',
              trigger: 'blur',
            },
          ]"
        >
          <el-select v-model="invoiceForm.yzInvoiceType" placeholder="请选择">
            <el-option
              v-for="item in yzInvoiceTypeList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item
      prop="filesName"
      label="附件上传"
      :rules="[
        {
          required: true,
          message: '请上传附件',
          trigger: 'change',
        },
      ]"
    >
      <upload
        ref="invoiceUpLoad"
        :fileName.sync="invoiceForm.filesName"
        @success="invoiceSuccess"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :loading="invoiceLoad" @click="goInvoice"
        >确定</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import upload from "@/components/servers/upload";
import Snowflake from "@/common/snowflake";
export default {
  components: { upload },
  props: {
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  data() {
    return {
      taskInvoiceId: new Snowflake(1, 1, 0).nextId(),
      invoiceLoad: false,
      invoiceForm: {
        description: "",
        money: "",
        yzInvoiceType: "",
        filesName: "",
      },
      yzInvoiceTypeList: [],
    };
  },
  mounted() {
    //发票类型
    this.$sapi
      .get("/api/v1/getDictDataList-yz_invoice_type.json")
      .then((res) => {
        this.yzInvoiceTypeList = res;
      });
  },
  methods: {
    //上传开票附件
    goInvoice() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          this.invoiceLoad = true;
          if (this.invoiceForm.filesName) {
            this.$refs.invoiceUpLoad.submit();
          } else {
            this.goSaveInvoice();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交开票资料
    goSaveInvoice() {
      this.$api
        .post(
          "/api/v1/commitTaskInvoice",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            description: this.invoiceForm.description,
            money: this.invoiceForm.money,
            yzInvoiceType: this.invoiceForm.yzInvoiceType,
            taskInvoiceId:this.taskInvoiceId
          })
        )
        .then((res) => {
          this.invoiceLoad = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
            onClose: () => {
              if (res.code == 1) {
                this.invoiceForm = {
                  description: "",
                  money: "",
                  yzInvoiceType: "",
                  filesName: "",
                };
                this.$refs.invoiceUpLoad.clear();
              }
            },
          });
        })
        .catch((err) => {
          this.invoiceLoad = false;
        });
    },
    //关联开票资料
    goAboutInvoice(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadTaskInvoiceFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskMainId: this.yzTaskMainId,
            taskInvoiceId: this.taskInvoiceId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.goSaveInvoice(res);
            } else {
              i++;
              //未结束，继续关联
              this.goAboutInvoice(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.invoiceLoad = false;
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
          this.invoiceLoad = false;
        });
    },
    //开票资料上传成功
    invoiceSuccess(files) {
      if (files) {
        this.goAboutInvoice(files);
      } else {
        this.goSaveInvoice();
      }
    },
  },
};
</script>