var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_vm._m(0),_c('div',{class:['file-list', _vm.preview ? 'on' : '']},[_c('el-form',{ref:"tablelists",staticClass:"tablelists-form",attrs:{"model":_vm.forms,"label-width":"100px","size":"small"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"size":"small","data":_vm.forms.yzTaskContractDataList,"row-class-name":_vm.tableRowClassName}},[_c('el-table-column',{attrs:{"prop":"sort","align":"center","label":"编号","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".sort")}},[(!_vm.preview)?_c('el-input',{attrs:{"size":"small"},model:{value:(scope.row.sort),callback:function ($$v) {_vm.$set(scope.row, "sort", $$v)},expression:"scope.row.sort"}}):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.sort))]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"零件名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".name"),"rules":{
                required: true,
                message: '请输入零件名称',
                trigger: 'blur',
              }}},[(!_vm.preview)?_c('el-input',{attrs:{"placeholder":"请输入零件名称"},model:{value:(scope.row.name),callback:function ($$v) {_vm.$set(scope.row, "name", $$v)},expression:"scope.row.name"}}):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.name))]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"picture","align":"center","label":"图片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".picture"),"rules":{
                required: true,
                message: '请上传图片',
                trigger: 'change',
              }}},[_c('upload',{directives:[{name:"show",rawName:"v-show",value:(!_vm.preview),expression:"!preview"}],ref:'upload' + scope.row.id,attrs:{"uploadType":"image","mode":"image","format":"png/jpg/svg","bizKey":_vm.bizKey,"keyName":scope.row.id,"fileName":scope.row.picture,"multiple":false,"filelist":scope.row.picture ? [{ fileUrl: scope.row.picture }] : []},on:{"update:fileName":function($event){return _vm.$set(scope.row, "picture", $event)},"update:file-name":function($event){return _vm.$set(scope.row, "picture", $event)},"success":_vm.upSuccess,"checkSuccess":_vm.checkSuccess}}),_c('el-input',{staticStyle:{"display":"none"},attrs:{"placeholder":"请上传图片"},model:{value:(scope.row.picture),callback:function ($$v) {_vm.$set(scope.row, "picture", $$v)},expression:"scope.row.picture"}}),(_vm.preview)?_c('p',[(scope.row.picture)?_c('el-image',{staticClass:"file-image",attrs:{"src":scope.row.picture,"fit":"cover"}}):_vm._e()],1):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"material","align":"center","label":"材料"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".material"),"rules":{
                required: true,
                message: '请输入材料名称',
                trigger: 'blur',
              }}},[(!_vm.preview)?_c('el-input',{attrs:{"placeholder":"请输入材料名称"},model:{value:(scope.row.material),callback:function ($$v) {_vm.$set(scope.row, "material", $$v)},expression:"scope.row.material"}}):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.material))]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"numbers","align":"center","label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".numbers"),"rules":{
                required: true,
                message: '请输入数量',
                trigger: 'blur',
              }}},[(!_vm.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入数量"},model:{value:(scope.row.numbers),callback:function ($$v) {_vm.$set(scope.row, "numbers", $$v)},expression:"scope.row.numbers"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("件")])]):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.numbers)+"件")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"yzCraftTypes","align":"center","label":"工艺"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".yzCraftTypes"),"rules":{
                required: true,
                message: '请选择工艺',
                trigger: 'change',
              }}},[(!_vm.preview)?_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(scope.row.yzCraftTypes),callback:function ($$v) {_vm.$set(scope.row, "yzCraftTypes", $$v)},expression:"scope.row.yzCraftTypes"}},_vm._l((_vm.gyList),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1):_vm._e(),(_vm.preview)?_c('p',[_vm._v(" "+_vm._s(_vm.gyName(scope.row.yzCraftTypes))+" ")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"price","align":"center","label":"未税单价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".price"),"rules":{
                required: true,
                message: '请输入未税单价',
                trigger: 'blur',
              }}},[(!_vm.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入未税单价"},model:{value:(scope.row.price),callback:function ($$v) {_vm.$set(scope.row, "price", $$v)},expression:"scope.row.price"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("元")])]):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.price)+"元")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"saxPrice","align":"center","label":"含税单价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("yzTaskContractDataList." + (scope.$index) + ".saxPrice"),"rules":{
                required: true,
                message: '请输入含税单价',
                trigger: 'blur',
              }}},[(!_vm.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入含税单价"},model:{value:(scope.row.saxPrice),callback:function ($$v) {_vm.$set(scope.row, "saxPrice", $$v)},expression:"scope.row.saxPrice"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("元")])]):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(scope.row.saxPrice)+"元")]):_vm._e()],1)]}}])}),(!_vm.preview)?_c('el-table-column',{attrs:{"prop":"sort","align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',[_c('el-button',{attrs:{"size":"small","disabled":scope.$index == 0,"type":"danger"},on:{"click":function($event){return _vm.del(scope.row)}}},[_vm._v(" 删除 ")])],1)]}}],null,false,3788732922)}):_vm._e()],1)],1),_c('div',{staticClass:"btn"},[(!_vm.preview)?_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.add}},[_vm._v("添加新零件")]):_vm._e(),(_vm.preview)?_c('div'):_vm._e(),_c('span',[_vm._v("合计："+_vm._s(_vm.totalPrice)+"元")])],1),_c('div',{staticClass:"form-main"},[_c('el-form',{ref:"forms",staticClass:"forms-dynamic",attrs:{"model":_vm.forms,"label-width":"100px","label-suffix":"：","size":"small"}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"days","label":"加工周期","rules":[
                {
                  required: true,
                  message: '请输入加工周期',
                  trigger: 'blur',
                } ]}},[(!_vm.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入加工周期"},model:{value:(_vm.forms.days),callback:function ($$v) {_vm.$set(_vm.forms, "days", $$v)},expression:"forms.days"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("天")])]):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(_vm.forms.days)+"天")]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"payType","label":"付款方式","rules":[
                {
                  required: true,
                  message: '请选择付款方式',
                  trigger: 'change',
                } ]}},[(!_vm.preview)?_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.forms.payType),callback:function ($$v) {_vm.$set(_vm.forms, "payType", $$v)},expression:"forms.payType"}},_vm._l((_vm.yzCashoutType),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(_vm.yzCashoutTypeName(_vm.forms.payType)))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"slipingType","label":"送货方式","rules":[
                {
                  required: true,
                  message: '请选择送货方式',
                  trigger: 'change',
                } ]}},[(!_vm.preview)?_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.forms.slipingType),callback:function ($$v) {_vm.$set(_vm.forms, "slipingType", $$v)},expression:"forms.slipingType"}},_vm._l((_vm.yzPostCompanyType),function(item){return _c('el-option',{key:item.dictValue,attrs:{"label":item.dictLabel,"value":item.dictValue}})}),1):_vm._e(),(_vm.preview)?_c('p',[_vm._v(" "+_vm._s(_vm.yzPostCompanyTypeName(_vm.forms.slipingType))+" ")]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"prop":"sax","label":"税费","rules":[
                { required: true, message: '请输入税费', trigger: 'blur' } ]}},[(!_vm.preview)?_c('el-input',{attrs:{"type":"number","placeholder":"请输入税费"},model:{value:(_vm.forms.sax),callback:function ($$v) {_vm.$set(_vm.forms, "sax", $$v)},expression:"forms.sax"}},[_c('span',{staticClass:"unit",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("%")])]):_vm._e(),(_vm.preview)?_c('p',[_vm._v(_vm._s(_vm.forms.sax)+"%")]):_vm._e()],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-title"},[_c('i',{staticClass:"iconfont icon-zhengpinbaozhangduigou"}),_vm._v("项目内容与周期 ")])}]

export { render, staticRenderFns }