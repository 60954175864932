<template>
  <div class="input-else">
    <div class="item">
      <div class="item-title">
        <i class="iconfont icon-zhengpinbaozhangduigou"></i>需求方权利义务责任
      </div>
      <div class="text-main" v-if="preview">
        {{ form.taskUserRight }}
      </div>
      <div class="input-main" v-if="!preview">
        <p>
          提示：需求方在项目中享有的权利和承担的责任，如付款、提供资料、配合义务等。
        </p>
        <el-input
          type="textarea"
          v-model="form.taskUserRight"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <i class="iconfont icon-zhengpinbaozhangduigou"></i>工厂权利义务责任
      </div>
      <div class="text-main" v-if="preview">
        {{ form.taskUserRight }}
      </div>
      <div class="input-main" v-if="!preview">
        <p>
          提示：工厂在项目中享有的权利和承担的责任，如提供服务事项、内容、责任等。
        </p>
        <el-input
          type="textarea"
          v-model="form.shoperUserRight"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <i class="iconfont icon-zhengpinbaozhangduigou"></i>验收标准及内容
      </div>
      <div class="text-main" v-if="preview">
        {{ form.taskUserRight }}
      </div>
      <div class="input-main" v-if="!preview">
        <p>
          提示：需求方与工厂应当明确知识工作者交付成果的标准、验收的内容、验收时间期限等。
        </p>
        <el-input
          type="textarea"
          v-model="form.checkContent"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <i class="iconfont icon-zhengpinbaozhangduigou"></i>违约责任
      </div>
      <div class="text-main" v-if="preview">
        {{ form.taskUserRight }}
      </div>
      <div class="input-main" v-if="!preview">
        <p>
          提示：需求方与工厂可就服务事项、服务质量、期限等不同情形，约定违约责任，违约责任可以是支付违约金、退款等方式。
        </p>
        <el-input
          type="textarea"
          v-model="form.breachContent"
          placeholder="请输入内容"
        ></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    value: {
      default: {},
      type: Object,
    },
    preview: {
      default: false,
      type: Boolean|Number,
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
