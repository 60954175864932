<template>
  <div>
    <div class="supplier-menu">
      <div class="supplier-menu-main">
        <div class="content">
          <a
            :href="item.url"
            v-for="item in menuList"
            :key="item.id"
            :class="['item', item.id == active ? 'on' : '']"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
    <div class="banner" v-if="banner">
      <div class="content">
        <img src="@/assets/images/public/supplier_back.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      default: 1,
      type: Number,
    },
    banner: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        // { id: 1, name: "在线制造平台", url: "/make" },
        { id: 1, name: "服务商库", url: "/supplier/list" },
        { id: 2, name: "招标大厅", url: "/service/list" },
        { id: 3, name: "热门服务", url: "/servers/list" },
        // { id: 4, name: "成功案例", url: "" },
        // { id: 5, name: "供应链协同", url: "" },
        // { id: 6, name: "政府招标", url: "" },
        // { id: 7, name: "易造学院", url: "" },
      ],
    };
  },
};
</script>