<template>
  <div class="fast-up">
    <div class="title">
      <h3>快速发布需求</h3>
      <p>快速发布，坐等专业顾问回复</p>
    </div>
    <div class="form">
      <el-form :model="upForm" ref="upForm">
        <el-form-item
          prop="categoryCode"
          :rules="[
            {
              required: true,
              message: '请选择服务分类',
              trigger: 'change',
            },
          ]"
        >
          <el-cascader
            filterable
            v-model="upForm.categoryCode"
            :options="classifyList"
            @change="upChange"
            popper-class="elCascader"
          >
          </el-cascader>
          <i class="iconfont icon-dayin"></i>
        </el-form-item>
        <el-form-item
          prop="title"
          :rules="[
            {
              required: true,
              message: '请输入需求标题',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            prefix-icon="el-icon-coin"
            v-model="upForm.title"
            placeholder="需求标题如：机器人设计"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="mobile"
          :rules="[
            {
              required: true,
              validator: validatorPhone,
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            prefix-icon="el-icon-mobile-phone"
            v-model="upForm.mobile"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-button
          class="block"
          :loading="upLoading"
          type="primary"
          @click="submitForm"
          >发布需求</el-button
        >
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      upForm: {
        categoryCode: [],
        title: "",
        mobile: "",
      },
      upLoading: false,
      classifyList: [],
    };
  },
  mounted() {
    //获取服务分类
    this.getClassifyData();
  },
  methods: {
    validatorPhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value != "" && !/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    upChange(arr) {
      debugger;
    },
    submitForm() {
      this.$refs.upForm.validate((valid) => {
        if (valid) {
          this.upLoading = true;
          let { categoryCode, title, mobile } = this.upForm;
          this.$api
            .post(
              "/api/v1/postQuickTaskMain",
              this.$common.getFormData({
                categoryCode: categoryCode[categoryCode.length - 1],
                title,
                mobile,
              })
            )
            .then((res) => {
              this.upForm = {
                categoryCode: [],
                title: "",
                mobile: "",
              };
              this.upLoading = false;
              this.$message.success(res.message);
            })
            .catch((err) => {
              this.upLoading = false;
            });
        }
      });
    },
    getClassifyData() {
      this.$api.get("/api/v1/getYzTaskCategoryList").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        this.classifyList = pList.map((item) => {
          if (_json[item.id]) {
            item.children = _json[item.id] || [];
            item.children = item.children.map((items) => {
              if (_json[items.id]) {
                items.children = _json[items.id];
                items.children = items.children.map((items2) => {
                  return {
                    value: items2.id,
                    label: items2.name,
                    leaf: true,
                  };
                });
              }
              let obj = {
                value: items.id,
                label: items.name,
              };
              if (items.children) {
                obj.children = items.children;
              } else {
                obj.leaf = true;
              }
              return obj;
            });
          }
          let obj = {
            value: item.id,
            label: item.name,
          };
          if (item.children && item.children.length) {
            obj.children = item.children;
          } else {
            obj.leaf = true;
          }
          return obj;
        });
      });
    },
  },
};
</script>