<template>
  <div class="chat-user" v-if="close">
    <el-image
      :src="
        detail.adviserUser&&detail.adviserUser.avatarUrl || require('@/assets/images/public/servers.png')
      "
      fit="cover"
    ></el-image>
    <h3>项目顾问-{{ detail.nickname }}</h3>
    <div class="tags">
      <span class="common-label">商务沟通</span>
      <span class="common-label">质量管理</span>
      <span class="common-label">项目管理</span>
    </div>
    <div class="desc">
      个人宣言：用来代表用户或事物，支 持图片、图标或字符展示
    </div>
    <div class="icon">
      <i class="iconfont icon-liaotian"></i>
      <p>在线沟通</p>
    </div>
    <span class="el-icon-close" @click="close=false;" ></span>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: {adviserUser:{}},
    },
  },
  data() {
    return {
        close:true
    };
  },
};
</script>
<style lang="scss">
</style>