<template>
  <div
    class="sign-status"
    v-if="
      contractInfo.taskUserStatus == 1 || contractInfo.shoperUserStatus == 1
    "
  >
    <div :class="['sign-item', contractInfo.taskUserStatus == 1 ? 'on' : '']">
      <p>雇主已完成实名认证</p>
      <p>甲方（雇主）：{{ contractInfo.taskUserContactName }}</p>
      <!-- <p>企业名称：{{ contractInfo.cropName }}</p> -->
      <p v-if="contractInfo.taskUserStatus == 1">
        签署日期：{{ taskUserOkTime || contractInfo.updateDate }}
      </p>
    </div>
    <div :class="['sign-item', contractInfo.shoperUserStatus == 1 ? 'on' : '']">
      <p>服务商已完成实名认证</p>
      <p>乙方（服务商）：{{ contractInfo.shoperUserContactName }}</p>
      <!-- <p>企业名称：{{ contractInfo.yzViewShopInfo.cropName }}</p> -->
      <p v-if="contractInfo.shoperUserStatus == 1">
        签署日期：{{ shopUserOkTime || contractInfo.updateDate }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      default: { selectShoperYzTaskEvaluation: {} },
      type: Object,
    },
    contractInfo: {
      default: {},
      type: Object,
    },
  },
  computed: {
    taskUserOkTime() {
      if (this.contractInfo.taskUserOkTime) {
        this.$common.getFormat({
          date: new Date(this.contractInfo.taskUserOkTime),
          format: "YYYY-MM-DD hh:mm",
        });
      } else {
        return "";
      }
    },
    shopUserOkTime() {
      if (this.contractInfo.shopUserOkTime) {
        this.$common.getFormat({
          date: new Date(this.contractInfo.shopUserOkTime),
          format: "YYYY-MM-DD hh:mm",
        });
      } else {
        return "";
      }
    },
  },
};
</script>