var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"supplementForm",staticClass:"pay-form",attrs:{"model":_vm.supplementForm,"label-position":"top","size":"small"}},[_c('el-form-item',{attrs:{"prop":"description","label":"补充需求","rules":[
      {
        required: true,
        message: '请补充需求',
        trigger: 'blur',
      } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":"10","placeholder":"请补充需求"},model:{value:(_vm.supplementForm.description),callback:function ($$v) {_vm.$set(_vm.supplementForm, "description", $$v)},expression:"supplementForm.description"}})],1),_c('el-form-item',{attrs:{"prop":"filesName","label":"附件上传","rules":[
      {
        required: true,
        message: '请上传附件',
        trigger: 'change',
      } ]}},[_c('upload',{ref:"supplementUpLoad",attrs:{"fileName":_vm.supplementForm.filesName},on:{"update:fileName":function($event){return _vm.$set(_vm.supplementForm, "filesName", $event)},"update:file-name":function($event){return _vm.$set(_vm.supplementForm, "filesName", $event)},"success":_vm.supplementSuccess}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.supplementLoad},on:{"click":_vm.goSupplement}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }