<template>
  <div class="step-two">
    <div class="steps">
      <el-steps :active="stepTwoActive" align-center>
        <el-step
          v-for="item in stepTwoList"
          :key="item.id"
          :title="stepJson[item.step] || item.step"
        ></el-step>
      </el-steps>
    </div>
    <div class="table-list">
      <el-table
        v-loading="tableLoad"
        :data="tableList"
        border
        size="small"
        style="width: 100%"
      >
        <el-table-column prop="date" align="center" label="项目进度">
          <template slot-scope="scope">
            {{ stepJson[scope.row.step] || scope.row.step }}
          </template>
        </el-table-column>
        <el-table-column prop="workContent" align="center" label="项目内容">
        </el-table-column>
        <el-table-column prop="name" align="center" label="阶段审核">
          <template slot-scope="scope">
            <upload
              v-if="
                (!scope.row.listResultFiles ||
                  !scope.row.listResultFiles.length) &&
                hasShop &&
                commonStatus.currentYzTaskOrderPlan
              "
              ref="upload"
              mode="craft"
              btnText="请上传阶段审核资料"
              format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
              uploadType="all"
              :keyName="scope.row.id"
              @checkSuccess="checkSuccess"
              @success="upSuccess"
            />
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listResultFiles ||
                  !scope.row.listResultFiles.length) &&
                hasShop &&
                !commonStatus.currentYzTaskOrderPlan
              "
            >
              未上传
            </p>
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listResultFiles ||
                  !scope.row.listResultFiles.length) &&
                !hasShop
              "
            >
              服务商未上传
            </p>
            <p
              class="step-status"
              v-if="
                scope.row.listResultFiles &&
                scope.row.listResultFiles.length &&
                hasShop
              "
            >
              已上传
            </p>
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.listResultFiles &&
                scope.row.listResultFiles.length &&
                !hasShop
              "
            >
              <el-table :data="scope.row.listResultFiles" height="260">
                <el-table-column
                  width="200"
                  property="fileName"
                  label="文件名称"
                ></el-table-column>
                <el-table-column width="100" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      @click="goDown(scope.row)"
                      size="mini"
                      icon="el-icon-download"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                slot="reference"
                icon="el-icon-download"
                type="primary"
                size="mini"
                >下载审核资料</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="问题反馈">
          <template slot-scope="scope">
            <upload
              v-if="
                (!scope.row.listQuestionFiles ||
                  !scope.row.listQuestionFiles.length) &&
                !hasShop &&
                commonStatus.currentYzTaskOrderPlan
              "
              ref="upload"
              mode="craft"
              btnText="请上传反馈资料"
              format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
              uploadType="all"
              :keyName="scope.row.id"
              @checkSuccess="checkSuccess"
              @success="upSuccess"
            />
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listQuestionFiles ||
                  !scope.row.listQuestionFiles.length) &&
                !hasShop &&
                !commonStatus.currentYzTaskOrderPlan
              "
            >
              未上传
            </p>
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listQuestionFiles ||
                  !scope.row.listQuestionFiles.length) &&
                hasShop
              "
            >
              需求方未上传
            </p>
            <p
              class="step-status"
              v-if="
                scope.row.listQuestionFiles &&
                scope.row.listQuestionFiles.length &&
                !hasShop
              "
            >
              已上传
            </p>
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.listQuestionFiles &&
                scope.row.listQuestionFiles.length &&
                hasShop
              "
            >
              <el-table :data="scope.row.listQuestionFiles" height="260">
                <el-table-column
                  width="200"
                  property="fileName"
                  label="文件名称"
                ></el-table-column>
                <el-table-column width="100" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      @click="goDown(scope.row)"
                      size="mini"
                      icon="el-icon-download"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                slot="reference"
                icon="el-icon-download"
                type="primary"
                size="mini"
                >下载反馈资料</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="状态确认">
          <template slot-scope="scope">
            <p
              :class="[
                'step-status',
                's' + scope.row.yzTaskOrderPlanOperateStatus,
              ]"
            >
              {{
                statusJson[scope.row.yzTaskOrderPlanOperateStatus] ||
                scope.row.yzTaskOrderPlanOperateStatus
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" align="center" label="预计完成时间">
          <template slot-scope="scope">
            <p v-if="scope.row.endTime">
              {{ scope.row.endTime.split(" ")[0] }}
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="f-btn" v-if="commonStatus.currentYzTaskOrderPlan">
        <el-button
          type="success"
          size="small"
          icon="el-icon-message"
          v-if="hasShop"
          :loading="load2"
          @click="goTip"
          >提示客户确认</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-video-play"
          v-if="hasShop && commonStatus.currentYzTaskOrderPlan.commitMoney == 0"
          :loading="load1"
          @click="goNext"
          >申请进入下一环节</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-message"
          v-if="!hasShop"
          :loading="load2"
          @click="goTip"
          >提示工厂上传阶段成果</el-button
        >
        <el-button
          type="success"
          size="small"
          icon="el-icon-circle-check"
          v-if="!hasShop"
          :loading="load1"
          @click="goNext"
          >确认执行</el-button
        >
      </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  data() {
    return {
      stepTwoActive: 0,
      tableList: [],
      stepJson: {},
      statusJson: {},
      commonStatus: {},
      stepTwoList: [],
      tableLoad: false,
      load1: false,
      load2: false,
    };
  },
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.createBy ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    //支付进度
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_type.json")
      .then((res) => {
        res.forEach((item) => {
          this.stepJson[item.dictValue] = item.dictLabel;
        });
      });
    //阶段状态
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_operate_status.json")
      .then((res) => {
        res.forEach((item) => {
          this.statusJson[item.dictValue] = item.dictLabel;
        });
      });
    this.inits();
  },
  methods: {
    goDown(row) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `${window.config.api}/a/file/download/${row.id}`;
    },
    goNext() {
      this.$confirm(
        `您确认${this.hasShop ? "申请进入下一环节" : "执行下一环节"}吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.load1 = true;
        let params = {
          yzTaskMainId: this.yzTaskMainId,
        };
        if (this.hasShop) {
          params.yzTaskOrderPlanId = this.commonStatus.currentYzTaskOrderPlan.id;
        } else {
          params.currentTaskPlanId = this.commonStatus.currentYzTaskOrderPlan.id;
        }
        this.$api
          .post(
            this.hasShop
              ? "/api/v1/sendYzTaskOrderPlanDoingNextMessage"
              : "/api/v1/taskUserMakeSureRunCurrentTaskOrderPlan",
            this.$common.getFormData(params)
          )
          .then((res) => {
            this.load1 = false;
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 2000,
            });
            this.inits();
          })
          .catch((err) => {
            this.load1 = false;
          });
      });
    },
    goTip() {
      this.load2 = true;
      let params = {
        yzTaskMainId: this.yzTaskMainId,
        yzTaskOrderPlanId: this.commonStatus.currentYzTaskOrderPlan.id,
      };
      this.$api
        .post(
          this.hasShop
            ? "/api/v1/sendYzTaskOrderPlanQualityMessage"
            : "/api/v1/sendYzTaskOrderPlanDoingUploadFileMessage",
          this.$common.getFormData(params)
        )
        .then((res) => {
          this.load2 = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 2000,
          });
        })
        .catch((err) => {
          this.load2 = false;
        });
    },
    inits() {
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlanStatistics",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.commonStatus = res.data;
            this.getTablelist();
          }
        });
    },
    getTablelist() {
      this.tableLoad = true;
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          this.tableLoad = false;
          this.stepTwoList = res.data;
          if (this.commonStatus.currentYzTaskOrderPlan) {
            this.tableList = res.data.filter((item, index) => {
              if (item.id == this.commonStatus.currentYzTaskOrderPlan.id) {
                this.stepTwoActive = index + 1;
              }
              return item.id == this.commonStatus.currentYzTaskOrderPlan.id;
            });
          } else {
            this.stepTwoActive = res.data.length;
            this.tableList = res.data;
            this.$emit('inits');
          }
        });
    },
    checkSuccess(files) {
      if (files) {
        this.$refs.upload.submit();
      }
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          this.hasShop
            ? "/api/v1/uploadYzTaskContractResultFile"
            : "/api/v1/uploadTaskOrderPlanQuestionFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskContractId: this.yzTaskMainId,
            yzTaskOrderPlanId: files[i].keyName,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.$message({
                message: "上传资料成功！",
                type: "success",
                duration: 1500,
              });
              this.inits();
            } else {
              i++;
              //未结束，继续关联
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
        });
    },
    upSuccess(files) {
      if (files) {
        this.goAbout(files);
      }
    },
  },
};
</script>