<template>
  <div class="quality-main">
    <div class="table-list">
      <el-table
        class="craft-table"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        size="small"
        style="width: 100%"
        border
      >
        <el-table-column label="质量检测文件名称" prop="fileName" width="200">
        </el-table-column>
        <el-table-column
          label="文件大小"
          prop="fileEntity.fileSizeFormat"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="上传时间"
          width="150"
          prop="createDate"
          align="center"
        >
        </el-table-column>
        <el-table-column label="下载量" prop="createByName" align="center">
          <template slot-scope="scope">
            <div>
              已下载
              <span style="color: #232ae6">
                {{ (scope.row.extend && scope.row.extend.extendI4) || 0 }}
              </span>
              次
            </div>
          </template>
        </el-table-column>
        <el-table-column label="需求方状态" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.extend && scope.row.extend.extendS2"
              :class="{
                'affirm-span': true,
                on: scope.row.extend && scope.row.extend.extendS2 == 1,
              }"
              >{{
                scope.row.extend && scope.row.extend.extendS2 == 1
                  ? "已确认"
                  : "已否决"
              }}</span
            >
            <span v-else>待确认</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="goDown(scope.row)"
              size="mini"
              icon="el-icon-download"
              >下载</el-button
            >
            <el-button
              v-if="hasShop && !isPass"
              type="danger"
              size="mini"
              icon="el-icon-circle-check"
              @click="del(scope.row)"
              :loading="scope.row.delLoad"
              >删除</el-button
            >
            <!-- <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS3) ||
                  !scope.row.extend) &&
                !hasShop
              "
              type="success"
              size="mini"
              icon="el-icon-circle-check"
              @click="goYes(scope.row, true)"
              :loading="scope.row.yesLoad"
              >确认</el-button
            >
            <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS3) ||
                  !scope.row.extend) &&
                !hasShop
              "
              type="danger"
              size="mini"
              icon="el-icon-circle-close"
              @click="goNot(scope.row, true)"
              :loading="scope.row.notLoad"
              >否决</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="f-btn" v-if="hasShop && !isPass">
      <upload
        ref="upload"
        mode="craft"
        btnText="上传质量检测文件"
        format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
        uploadType="all"
        @checkSuccess="checkSuccess"
        @success="upSuccess"
      />
      <el-button
        type="success"
        size="small"
        icon="el-icon-message"
        @click="goSend"
        >提醒用户质量检查</el-button
      >
    </div>
    <div class="f-btn" v-if="!hasShop && !isPass" >
      <el-button
        size="small"
        type="success"
        icon="el-icon-circle-check"
        :loading="yesLoad"
        @click="goYes2()"
      >
        全部同意
      </el-button>
      <el-button
        :loading="notLoad"
        size="small"
        type="danger"
        icon="el-icon-circle-close"
        @click="goNot2()"
      >
        全部否决
      </el-button>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    detail: {
      default() {
        return {};
      },
      type: Object,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.createBy ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  data() {
    return {
      description: "",
      taskUserSureExecuteStatus: false,
      shoperUserSureExecuteStatus: false,
      hasSort: false,
      tableLoad: false,
      days: 0,
      activeName: "1",
      projectForm: { duration: "", filesName: "" },
      tableData: [],
      forms: { yzTaskOrderPlanList: [] },
      stepList: [],
      stepJson: {},
      statusList: [],
      statusJson: {},
      theId: "",
      operateStatusList: [
        { dictLabel: "已确认", dictValue: "1" },
        { dictLabel: "未确认", dictValue: "0" },
      ],
      operateStatusJson: { 1: "已确认", 0: "未确认" },
      sortable: null,
      oldList: [],
      newList: [],
      sortKey: new Date().getTime(), // 为了方便拖动失败，回到拖动之前
      selectArray: [],
      commonStatus: {},
      yesLoad: false,
      notLoad: false,
      isPass: true,
    };
  },
  mounted() {
    this.inits();
    //支付进度
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_type.json")
      .then((res) => {
        this.stepList = res;
        res.forEach((item) => {
          this.stepJson[item.dictValue] = item.dictLabel;
        });
      });
    //阶段状态
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_pay.json")
      .then((res) => {
        this.statusList = res;
        res.forEach((item) => {
          this.statusJson[item.dictValue] = item.dictLabel;
        });
      });
  },
  methods: {
    goSend() {
      this.$api
        .post(
          "/api/v1/sendYzTaskOrderPlanQualityMessage",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            yzTaskOrderPlanId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        });
    },
    goYes(row, is) {
      this.tableData = this.tableData.map((item) => {
        if (item.id == row.id) {
          item.yesLoad = true;
        }
        return item;
      });
      this.$api
        .post(
          !is ? "/api/v1/shoperUserSureMake" : "/api/v1/taskUserSureMake",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            fileUploadId: row.id,
          })
        )
        .then((res) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.yesLoad = false;
            }
            return item;
          });
          if (res.code == 1) {
            this.inits();
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.yesLoad = false;
            }
            return item;
          });
        });
    },
    goNot(row, is) {
      this.tableData = this.tableData.map((item) => {
        if (item.id == row.id) {
          item.notLoad = true;
        }
        return item;
      });
      this.$api
        .post(
          !is ? "/api/v1/taskUserRefuseMake" : "/api/v1/shoperUserRefuseMake",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            fileUploadId: row.id,
          })
        )
        .then((res) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.notLoad = false;
            }
            return item;
          });
          if (res.code == 1) {
            this.inits();
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.notLoad = false;
            }
            return item;
          });
        });
    },
    goYes2() {
      this.$confirm(`您确认所有的质量检测都通过吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.yesLoad = true;
        this.$api
          .post(
            "/api/v1/maskSureQuilityTaskOrderPlan",
            this.$common.getFormData({
              yzTaskOrderId: this.yzTaskMainId,
            })
          )
          .then((res) => {
            this.yesLoad = false;
            if (res.code == 1) {
              this.inits();
              this.$emit('inits');
            }
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
          })
          .catch((err) => {
            this.yesLoad = false;
          });
      });
    },
    goNot2() {
      this.$confirm(`您确认所有的质量检测都不通过吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.notLoad = true;
        this.$api
          .post(
            "/api/v1/sendYzTaskOrderPlanQualityResuseFileMessage",
            this.$common.getFormData({
              yzTaskOrderId: this.yzTaskMainId,
            })
          )
          .then((res) => {
            this.notLoad = false;
            if (res.code == 1) {
              this.inits();
              this.description = "";
            }
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
          })
          .catch((err) => {
            this.notLoad = false;
          });
      });
    },
    goDown(row) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `${window.config.api}/a/file/download/${row.id}`;
    },
    del(row) {
      this.tableData = this.tableData.map((item) => {
        if (item.id == row.id) {
          item.delLoad = true;
        }
        return item;
      });
      this.$confirm(`您确认删除${row.fileName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.tableData = this.tableData.map((item) => {
          if (item.id == row.id) {
            item.delLoad = false;
          }
          return item;
        });
        this.$api
          .post(
            "/api/v1/unRemoveQuilityForm",
            this.$common.getFormData({
              bizKey: row.bizKey,
              fileUploadId: row.id,
            })
          )
          .then((res) => {
            this.yesLoad = false;
            if (res.code == 1) {
              this.inits();
            }
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
          })
          .catch((err) => {
            this.tableData = this.tableData.map((item) => {
              if (item.id == row.id) {
                item.delLoad = false;
              }
              return item;
            });
          });
      });
    },
    getData() {
      this.$api
        .post(
          "/api/v1/getTaskOrderQuilityListFile",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.map((item) => {
              item.yesLoad = false;
              item.notLoad = false;
              item.delLoad = false;
              return item;
            });
          }
        });
    },
    inits() {
      this.getData();
      this.$api
        .post(
          "/api/v1/isTaskOrderPlanQualityCount",
          this.$common.getFormData({
            taskOrderId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.data) {
            this.isPass = true;
          } else {
            this.isPass = false;
          }
        });
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadYzTaskQuilityFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskContractId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.$message({
                message: "上传质量检测文件成功！",
                type: "success",
                duration: 1500,
              });
              this.inits();
            } else {
              i++;
              //未结束，继续关联
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
        });
    },
    checkSuccess(files) {
      if (files) {
        this.$refs.upload.submit();
      }
    },
    upSuccess(files) {
      if (files) {
        this.goAbout(files);
      }
    },
    handleSelectionChange(selection) {
      this.selectArray = selection;
      console.log(selection);
    },
  },
};
</script>